import { group, control } from "@reactables/forms";
import { primaryMember } from "@basicare/common/src/Rx/Configs/primaryMember.config";

export const generalInformation = group({
  controls: {
    planType: control([null, "required"]),
    ...primaryMember().controls,
    confirmEmail: control({
      initialValue: "",
      validators: ["required", "email"],
      normalizers: ["normalizeEmail"],
    }),
  },
  validators: ["emailConfirmed"],
});
