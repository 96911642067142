import React from "react";
import { useLocation } from "react-router-dom";
import { Breadcrumb as IBreadcrumb } from "../../Models/breadcrumb.model";
import Breadcrumb from "./Breadcrumb";

const Breadcrumbs = ({
  disableNavigation = false,
}: {
  disableNavigation?: boolean;
}) => {
  const location = useLocation();

  const breadcrumbs: IBreadcrumb[] = [
    {
      step: 1,
      text: "General Information",
      link: "/",
    },
    {
      step: 2,
      text: "Dependents",
      link: "/dependents",
    },
    {
      step: 3,
      text: "Payment",
      link: "/payment",
    },
  ];

  const currentStep =
    breadcrumbs.find(({ link }) => location.pathname === link)?.step || 1;

  return (
    <div className="breadcrumbs__container">
      <div className="breadcrumbs__row">
        {breadcrumbs.map(({ step, text, link }, i) => {
          const isActive = currentStep === step;
          return (
            <div key={step} className="breadcrumbs__col">
              <Breadcrumb
                {...{ step, text, link }}
                currentStep={currentStep}
                isActive={isActive}
                disableNavigation={disableNavigation || isActive}
                disabled={step > currentStep}
                hasArrow={i < breadcrumbs.length - 1}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Breadcrumbs;
