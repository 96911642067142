import {
  ApplyPromoCodePayload,
  ApplyPromoCodeResponse,
} from "Features/MembershipSignUp/Models/promoCodes.model";
import API from "Helpers/API";
import { AxiosResponse } from "axios";

class PromoCodeService {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  getApplyPromoCode(
    params: ApplyPromoCodePayload
  ): Promise<AxiosResponse<ApplyPromoCodeResponse>> {
    return this.api.get({
      location: `/promo-codes/redeem`,
      params,
    });
  }
}

export default PromoCodeService;
