import { useReactable } from "@reactables/react";
import { Button as AltButton } from "@jauntin/react-ui";
import Button from "@basicare/common/src/Components/Themed/Button";
import { ControlModels } from "@reactables/forms";
import { ReactComponent as ChevronLeft } from "@basicare/common/src/Assets/Images/chevron-left-solid.svg";
import NextButton from "Features/Shared/Components/NextButton";
import { Link } from "react-router-dom";
import { RxMembershipSignUpProp } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/RxMembershipSignUp";
import { RxIndex } from "Features/Shared/Rx/RxIndex";
import Summary from "../Summary/Summary";
import SummaryToggleBtn from "../Summary/SummaryToggleBtn";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import DependentForm from "./DependentForm";
import DependentCard from "./DependentCard";
import ModalConfirmation from "@basicare/common/src/Components/ModalConfirmation";

import * as FormSelectors from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/form.selector";
import * as DependentsSelectors from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/dependents.selector";

const Dependents = ({
  rxMembershipSignUp,
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
}) => {
  const [state, actions] = rxMembershipSignUp;

  const [removeDependentIndex, removeDependentIndexActions] = useReactable(
    RxIndex,
    "rxRemovingDependentIndex"
  );

  const {
    dependents: { dependentsList },
  } = FormSelectors.getRootValue(state);

  const addDependentFormControl = FormSelectors.getControl(
    state,
    "dependents.addDependentForm"
  ) as ControlModels.FormControl<Dependent>;

  const spouseSelected = DependentsSelectors.getIsSpouseSelected(state);

  const disableAddDependent =
    DependentsSelectors.getIsAddDependentDisabled(state);

  const oneSpouseMaxError = FormSelectors.getControl(
    state,
    "dependents.dependentsList"
  ).errors.oneSpouseMax;

  return (
    <div className="container container--fullwidth-mobile">
      <div className="page-heading">
        <h1>STEP 2: PROVIDE INFORMATION FOR EACH DEPENDENT</h1>
      </div>
      <div className="page-wrapper">
        <div className="row row--fullwidth-mobile">
          <div className="col-lg-7 col--fullwidth-mobile">
            <div className="page-content flex-grow-1 d-flex flex-column">
              <Breadcrumbs />
              <section className="page-content__section">
                <h2 className="mb-1">Dependents</h2>
                <div>
                  Your membership includes up to eight (8) dependents without
                  additional charge.
                  <br />
                  No dependents? Skip this step.
                </div>
                <AltButton
                  className="btn btn-link pl-0 c-primary mb-3 mt-2"
                  disabled={disableAddDependent}
                  text="+ Add Dependent"
                  onClick={actions.applicationForm.addDependent}
                ></AltButton>
                {addDependentFormControl && (
                  <DependentForm
                    className="mb-3"
                    dependentControl={addDependentFormControl}
                    dependentDobControl={
                      FormSelectors.getControl(
                        state,
                        "dependents.addDependentForm.dateOfBirth"
                      ) as ControlModels.FormControl<string>
                    }
                    disableSpouseRadio={spouseSelected}
                    save={actions.applicationForm.saveNewDependent}
                    cancel={actions.applicationForm.cancelNewDependent}
                    hasVisibleErrors={DependentsSelectors.getAddDependentShowingErrors(
                      state
                    )}
                  />
                )}
                {dependentsList.map(({ confirmed, dependent }, index) =>
                  confirmed ? (
                    <DependentCard
                      className="mb-3"
                      key={index}
                      dependent={dependent}
                      edit={() => actions.applicationForm.editDependent(index)}
                      remove={() => removeDependentIndexActions.setIndex(index)}
                      disabled={Boolean(
                        FormSelectors.getControl(
                          state,
                          "dependents.addDependentForm"
                        ) as ControlModels.FormControl<string>
                      )}
                    />
                  ) : (
                    <DependentForm
                      className="mb-3"
                      key={index}
                      dependentControl={
                        FormSelectors.getControl(
                          state,
                          `dependents.dependentsList.${index}.dependent`
                        ) as ControlModels.FormControl<Dependent>
                      }
                      dependentDobControl={
                        FormSelectors.getControl(
                          state,
                          `dependents.dependentsList.${index}.dependent.dateOfBirth`
                        ) as ControlModels.FormControl<string>
                      }
                      disableSpouseRadio={spouseSelected}
                      editMode
                      save={() =>
                        actions.applicationForm.saveDependentEdit(index)
                      }
                      cancel={() =>
                        actions.applicationForm.cancelDependentEdit(index)
                      }
                    />
                  )
                )}
                {typeof removeDependentIndex === "number" && (
                  <>
                    <ModalConfirmation
                      show
                      message={
                        dependentsList[removeDependentIndex] ? (
                          <>
                            <h2 className="text-center border-bottom pb-4">
                              Are you sure you want to remove this dependent?
                            </h2>
                            <DependentCard
                              dependent={
                                dependentsList[removeDependentIndex].dependent
                              }
                            />
                          </>
                        ) : (
                          <></>
                        )
                      }
                      confirmBtnText="Yes"
                      cancelBtnText="No"
                      confirmBtnClassName="w--70"
                      cancelBtnClassName="btn btn-outline-primary font-weight-bold border-radius-4 w--70"
                      handleClose={removeDependentIndexActions.clearIndex}
                      requireCheckbox={false}
                      action={() => {
                        actions.applicationForm.removeDependent(
                          removeDependentIndex
                        );
                        removeDependentIndexActions.clearIndex();
                      }}
                    />
                  </>
                )}
                {oneSpouseMaxError && (
                  <div className="form-error">
                    Only a maximum of one spouse can be added.
                  </div>
                )}
              </section>

              <div className="page-content__section mt-auto mb-0 row">
                <Link to="/" className="col-6 col-lg-auto pl-0">
                  <Button
                    outline
                    className="w-100 d-flex justify-content-center align-items-center"
                  >
                    <ChevronLeft className="button__chevrone-right mr-1" /> Back
                  </Button>
                </Link>
                <Link to="/payment" className="col-6 col-lg-auto pr-0">
                  <NextButton
                    className="w-100"
                    disabled={
                      !DependentsSelectors.getIsDependentsPageValid(state)
                    }
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-5">
            <Summary rxMembershipSignUp={rxMembershipSignUp} />
          </div>
          <SummaryToggleBtn rxMembershipSignUp={rxMembershipSignUp} />
        </div>
      </div>
    </div>
  );
};

export default Dependents;
