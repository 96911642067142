import { WrappedFieldProps } from "@reactables/react-forms/dist/Components/Field";
import { Plan } from "@basicare/common/src/Models/plan.model";
import { currencyFromInt } from "@basicare/common/src/Helpers/CurrencyHelper";
import { classname } from "@jauntin/utilities";

interface PlanBtnProps extends WrappedFieldProps {
  cols: string;
  plan: Plan;
  newCost?: number;
  [key: string]: unknown;
}

const PlanBtn = ({
  input,
  meta,
  cols = "4",
  plan,
  newCost,
  ...props
}: PlanBtnProps) => {
  const hasDiscount = newCost !== undefined;
  return (
    <div
      className={`btn-group-toggle col-sm-12 col-md-${cols}`}
      data-toggle="button"
    >
      <label
        className={`btn plan ${plan.planType} ${
          input.value === props.value ? "active" : ""
        }`}
        htmlFor={`${input.name}${props.value}`}
      >
        <input
          id={`${input.name}${props.value}`}
          type="radio"
          {...input}
          {...props}
          checked={input.value === props.value}
        />
        <div className="plan__interval text-capitalize">{plan.planType}</div>
        <div
          className={classname(
            "plan__price",
            hasDiscount && "text-line-through c-primary-2"
          )}
        >
          {currencyFromInt(plan.price).formatDollars()}
        </div>
        {hasDiscount && (
          <div className="plan__price mt-md-2">
            {currencyFromInt(newCost).formatDollars()}
          </div>
        )}
        {plan.discount > 0 && !hasDiscount && (
          <div className="plan__discount">
            <span>SAVE {plan.discount}%</span>
          </div>
        )}
      </label>
    </div>
  );
};

export default PlanBtn;
