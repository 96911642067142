import { HostedFieldsTokenizePayload } from "braintree-web";
import {
  CreateMembershipPayload,
  CardInfo,
} from "Features/MembershipSignUp/Models/createMembership.model";
import { RxMembershipSignUpState } from "../RxMembershipSignUp";
import { getControl, getRootValue } from "./form.selector";
import { plans } from "@basicare/common/src/Constants/planTypes";
import { MembershipSource } from "@basicare/common/src/Constants/membership";
import { FieldValidStates } from "Features/Shared/Components/BrainTreeCCFields";

export const getFieldValidStates = (state: RxMembershipSignUpState) =>
  ({
    number: getControl(state, "payment.billing.ccFieldsValidity.number"),
    cardholderName: getControl(
      state,
      "payment.billing.ccFieldsValidity.cardholderName"
    ),
    expirationDate: getControl(
      state,
      "payment.billing.ccFieldsValidity.expirationDate"
    ),
    cvv: getControl(state, "payment.billing.ccFieldsValidity.cvv"),
  } as FieldValidStates);

export const getCreateMembershipPayload = (
  $state: RxMembershipSignUpState,
  hostedFieldsTokenizePayload: HostedFieldsTokenizePayload | null
): CreateMembershipPayload => {
  const { generalInformation, dependents, payment } = getRootValue($state);

  const {
    planType,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    address: address1,
    address2,
    city,
    state,
    zip,
    email,
    confirmEmail,
    phoneNumber: phone,
  } = generalInformation;

  const {
    applyPromoCode: { data: promoCodeDetails },
  } = $state;

  const cardInfo: CardInfo = {
    token: hostedFieldsTokenizePayload?.nonce,
    lastFour: hostedFieldsTokenizePayload?.details.lastFour,
    cardType: hostedFieldsTokenizePayload?.details.cardType,
    nameOnCard: hostedFieldsTokenizePayload?.details.cardholderName,
    amountDetails: {
      totalAmount: (() => {
        if (promoCodeDetails?.newCost !== undefined) {
          return promoCodeDetails.newCost;
        }

        return plans.find((plan) => plan.planType === planType).price;
      })(),
      currency: "USD",
    },
  };

  return {
    subscriptionInterval: planType,
    firstName,
    lastName,
    dateOfBirth,
    gender,
    primaryAddress: {
      address1,
      address2,
      city,
      state,
      zip,
      country: "US",
    },
    email,
    confirmEmail,
    phone,
    dependents: dependents.dependentsList.map(({ dependent }) => dependent),
    billingAddress: (() => {
      if (!payment?.billing.differentAddressFields) return undefined;

      const {
        address: { address: address1, address2, ...billingAddress },
        firstName,
        lastName,
      } = payment.billing.differentAddressFields;

      return {
        firstName,
        lastName,
        address1,
        address2: address2 || "",
        ...billingAddress,
        country: "US",
      };
    })(),
    consentConfirmed: payment?.authorizationConsent,
    cardInfo,
    organizationNumber: $state.facilityReferral.facilityCode || undefined,
    promoCode: promoCodeDetails?.code,
    source: MembershipSource.APP_PURCHASE,
  };
};
