import { RxMembershipSignUpState } from "../RxMembershipSignUp";
import { hasControlTouchError } from "@basicare/common/src/Rx/Selectors/hasControlTouchError.selector";

export const getControl = (state: RxMembershipSignUpState, key = "root") =>
  state.applicationForm[key];

export const getRootValue = (state: RxMembershipSignUpState) => {
  return state.applicationForm.root.value;
};

export const getValue = (
  state: RxMembershipSignUpState,
  key: string
): unknown => {
  return state.applicationForm[key]?.value;
};

export const getHasControlTouchError =
  (groupName: string = "root") =>
  (state: RxMembershipSignUpState) => {
    return hasControlTouchError(state.applicationForm, groupName);
  };

export const getShowGroupActionButton = ({
  state,
  submitTouched,
  groupName = "root",
}: {
  state: RxMembershipSignUpState;
  submitTouched: boolean;
  groupName?: string;
}) =>
  state.applicationForm[groupName].valid ||
  getHasControlTouchError(groupName)(state) ||
  submitTouched;
