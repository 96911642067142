import { of } from "rxjs";
import { RxBuilder } from "@jauntin/reactables";
import { facilityReferralSuccess } from "./Reducers/facilityReferralSuccess.reducer";
import { handleFacilityReferral } from "./Operators/facility.operator";
import FacilityService from "Services/FacilityService";

export interface RxFacilityReferralState {
  facilityName: string;
  facilityCode: string;
  facilityLogoUrl?: string;
}

export const RxFacilityReferral = ({
  facilityService,
  initialState = {
    facilityName: "",
    facilityCode: "",
    facilityLogoUrl: null,
  } as RxFacilityReferralState,
  referralParams = "",
  onFacilityReferralError,
}: {
  facilityService: FacilityService;
  initialState: RxFacilityReferralState;
  referralParams: string;
  onFacilityReferralError: () => void;
}) =>
  RxBuilder({
    name: "rxFacilityReferral",
    sources: [
      of(referralParams).pipe(
        handleFacilityReferral(facilityService, onFacilityReferralError)
      ),
    ],
    initialState,
    reducers: {
      facilityReferralSuccess,
    },
  });
