import { ActionMap, Reactable, Action } from "@reactables/core";
import {
  RxBuilder,
  FormBuilders,
  Reducers,
  RxToggle,
} from "@jauntin/reactables";
import { combineLatest, from, of } from "rxjs";
import { mergeMap, catchError, map, tap } from "rxjs/operators";
import {
  ControlModels,
  RxFormActions,
  control,
  group,
} from "@reactables/forms";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import ContactService from "Services/ContactService";
import RecaptchaService from "Services/RecaptchaService";
const { build } = FormBuilders;

export interface ContactUsPayload {
  name: string;
  company: string;
  email: string;
  message: string;
  contactPreference: Dependent[];
}

type ContactUsFormState = ControlModels.Form<ContactUsPayload>;

type ContactUsFormActions = RxFormActions & ActionMap;

const RxContactUsForm = () =>
  build(
    group({
      controls: {
        name: control(["", "required"]),
        company: control([""]),
        email: control({
          initialValue: "",
          validators: ["required", "email"],
          normalizers: ["normalizeEmail"],
        }),
        message: control(["", "required"]),
        contactPreference: control(["", "email"]),
      },
    }),
    {
      name: "rxContactUs",
      providers: formProviders,
    }
  ) as Reactable<ContactUsFormState, ContactUsFormActions>;

export type ContactUsState = {
  form: ContactUsFormState;
  submitTouched: boolean;
  contactUsSubmission: Reducers.LoadableState<ContactUsPayload>;
};

export type ContactUsActions = {
  form: ContactUsFormActions;
  touchSubmit: () => void;
  submit: (payload: ContactUsPayload) => void;
};

export const RxContactUs = ({
  recaptchaService,
  contactService,
  onSubmitSuccess,
}: {
  recaptchaService: RecaptchaService;
  contactService: ContactService;
  onSubmitSuccess?: () => void;
}): Reactable<ContactUsState, ContactUsActions> => {
  const [$form, formActions] = RxContactUsForm();

  const [submitTouched$, { toggleOn: touchSubmit }] = RxToggle();

  const [contactUsSubmission$, { submit }] = RxBuilder({
    name: "rxSubmission",
    initialState: Reducers.loadableInitialState,
    reducers: {
      submit: {
        reducer: Reducers.load,
        effects: [
          ($submit) =>
            $submit.pipe(
              mergeMap(({ payload }: Action<ContactUsPayload>) => {
                return from(recaptchaService.getToken()).pipe(
                  mergeMap((captcha) => {
                    return from(
                      contactService.sendContactForm({
                        ...payload,
                        captcha,
                      })
                    );
                  }),
                  map(() => ({ type: "submitSuccess" })),
                  tap(() => {
                    onSubmitSuccess && onSubmitSuccess();
                  }),
                  catchError((error) =>
                    of({ type: "submitFailure", payload: error })
                  )
                );
              })
            ),
        ],
      },
      submitSuccess: Reducers.loadSuccess,
      submitFailure: Reducers.loadError,
    },
  });

  const $state = combineLatest({
    form: $form,
    submitTouched: submitTouched$,
    contactUsSubmission: contactUsSubmission$,
  });

  const actions = {
    form: formActions,
    touchSubmit,
    submit,
  };

  return [$state, actions];
};
