import { from, of } from "rxjs";
import { map } from "rxjs/operators";
import { RxBuilder, Reducers } from "@jauntin/reactables";
import { switchMap, mergeMap, catchError, tap } from "rxjs/operators";
import PaymentService from "Services/PaymentService";
import { Action } from "@reactables/core";
import { RxMembershipSignUpState } from "../RxMembershipSignUp/RxMembershipSignUp";
import { getCreateMembershipPayload } from "../RxMembershipSignUp/Selectors/payment.selector";
import {
  CreateMembershipResponse,
  CreateMembershipResult,
} from "Features/MembershipSignUp/Models/createMembership.model";
import {
  getPlanPrice,
  getPlanType,
} from "../RxMembershipSignUp/Selectors/generalInformation.selector";
import { Tokenizer } from "Features/Shared/Hooks/useHostedFields";
import { HostedFieldsTokenizePayload } from "braintree-web";

export interface RxPurchaseActions {
  purchase: (payload: {
    state: RxMembershipSignUpState;
    tokenizer: Tokenizer;
  }) => void;
  resetPurchase: () => void;
}

export const RxPurchase = ({
  initialState,
  paymentService,
  onPurchase,
}: {
  initialState: Reducers.LoadableState<CreateMembershipResponse>;
  paymentService: PaymentService;
  onPurchase?: (result: CreateMembershipResult) => void;
}) =>
  RxBuilder({
    name: "rxPurchase",
    initialState: initialState || Reducers.loadableInitialState,
    reducers: {
      resetPurchase: () => Reducers.loadableInitialState,
      purchase: {
        reducer: Reducers.load,
        effects: [
          (purchaseAction$) =>
            purchaseAction$.pipe(
              switchMap(
                ({
                  payload: { state, tokenizer },
                }: Action<{
                  state: RxMembershipSignUpState;
                  tokenizer: Tokenizer | null;
                }>) => {
                  const tokenPromise = tokenizer
                    ? tokenizer()
                    : new Promise((resolve) => resolve(null));
                  return from(tokenPromise).pipe(
                    mergeMap(
                      (
                        tokenizedPayload: HostedFieldsTokenizePayload | null
                      ) => {
                        // Map form payload here and call createCoverage api here with nounce
                        const requestPayload = getCreateMembershipPayload(
                          state,
                          tokenizedPayload
                        );

                        return from(
                          paymentService.createMembership(requestPayload)
                        ).pipe(
                          map((successResponse) => ({
                            type: "purchaseSuccess",
                            payload: successResponse.data,
                          }))
                        );
                      }
                    ),
                    tap(({ payload: { subscriberNumber } }) => {
                      onPurchase &&
                        onPurchase({
                          subscriberNumber,
                          total: getPlanPrice(state),
                          planType: getPlanType(state),
                        });
                    }),
                    catchError((e) => {
                      return of({ type: "purchaseFailure", payload: e });
                    })
                  );
                }
              )
            ),
        ],
      },
      purchaseSuccess: Reducers.loadSuccess,
      purchaseFailure: Reducers.loadError,
    },
  });
