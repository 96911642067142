import { useEffect } from "react";
import { classname } from "@jauntin/utilities";
import braintree, { HostedFields, HostedFieldsEvent } from "braintree-web";
import { ReactComponent as DiscoverIcon } from "@basicare/common/src/Assets/Images/CCIcons/Discover.svg";
import { ReactComponent as VisaIcon } from "@basicare/common/src/Assets/Images/CCIcons/VISA.svg";
import { ReactComponent as MasterCardIcon } from "@basicare/common/src/Assets/Images/CCIcons/Mastercard.svg";
import { ControlModels } from "@reactables/forms";

export type FieldValidStates = {
  number: ControlModels.FormControl<boolean>;
  cardholderName: ControlModels.FormControl<boolean>;
  expirationDate: ControlModels.FormControl<boolean>;
  cvv: ControlModels.FormControl<boolean>;
};

const BrainTreeCCFields = ({
  clientToken = process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY,
  fieldValidStates,
  onHostedFieldsLoaded,
  onValidityChange,
  onBlur,
  styles = {
    input: {
      "font-size": "1rem",
    },
  },
  enableShowErrors = false,
}: {
  clientToken?: string;
  fieldValidStates: FieldValidStates;
  onHostedFieldsLoaded?: (hostedFields: HostedFields) => void;
  onValidityChange?: (event: HostedFieldsEvent) => void;
  onBlur?: (event: HostedFieldsEvent) => void;
  styles?: Record<string, Record<string, string>>;
  enableShowErrors?: boolean;
}) => {
  /**
   * Provide useEffect stringified styles object
   * so it doesn't rerun effect unnecessarily on receiving new objects
   */
  const stringifiedStyles = JSON.stringify(styles);

  useEffect(() => {
    if (clientToken) {
      braintree.client.create(
        {
          authorization: clientToken,
        },
        (err, client) => {
          braintree.hostedFields.create(
            {
              client,
              styles: JSON.parse(stringifiedStyles),
              fields: {
                number: {
                  selector: "#card-number",
                  placeholder: "0000 0000 0000 0000",
                },
                cardholderName: {
                  selector: "#cardholder-name",
                  placeholder: "Name on Card",
                },
                cvv: {
                  selector: "#cvv",
                  placeholder: "Security Code/CVV2",
                },
                expirationDate: {
                  selector: "#expiration-date",
                  placeholder: "MM/YY",
                },
              },
            },
            (err, hostedFields) => {
              if (err) {
                console.log(err);
              } else {
                onHostedFieldsLoaded && onHostedFieldsLoaded(hostedFields);
                hostedFields.on("validityChange", (e) => {
                  onValidityChange && onValidityChange(e);
                });

                hostedFields.on("blur", (e) => {
                  onBlur && onBlur(e);
                });
              }
            }
          );
        }
      );
    }
  }, [
    clientToken,
    onHostedFieldsLoaded,
    onValidityChange,
    onBlur,
    stringifiedStyles,
  ]);

  const showError = (key: string) =>
    Boolean(
      fieldValidStates &&
        (fieldValidStates[key].touched || enableShowErrors) &&
        fieldValidStates[key].errors.required
    );

  return (
    <div className="brain-tree-cc-fields">
      <div className="row">
        <div className="col-12 col-sm-6 form-group">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor="card-number"
            className={classname(
              showError("number") && "form-error__label",
              "label--required"
            )}
          >
            Card Number
          </label>
          {showError("number") && (
            <div className="form-error">Invalid credit card number</div>
          )}
          <div
            className={classname(
              "form-control input-group",
              showError("number") && "form-error"
            )}
            id="card-number"
          >
            <div className="brain-tree-cc-fields__cc-icons">
              <VisaIcon className="mr-2" />
              <MasterCardIcon className="mr-2" />
              <DiscoverIcon />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 form-group">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor="card-name"
            className={classname(
              showError("cardholderName") && "form-error__label",
              "label--required"
            )}
          >
            Name on Card
          </label>
          {showError("cardholderName") && (
            <div className="form-error">Invalid cardholder name</div>
          )}
          <div
            className={classname(
              "form-control",
              showError("cardholderName") && "form-error"
            )}
            id="cardholder-name"
          ></div>
        </div>
        <div className="col-12 col-sm-6 form-group">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor="expiration-date"
            className={classname(
              showError("expirationDate") && "form-error__label",
              "label--required"
            )}
          >
            Expiration Date
          </label>
          {showError("expirationDate") && (
            <div className="form-error">Invalid expiration date</div>
          )}
          <div
            className={classname(
              "form-control",
              showError("expirationDate") && "form-error"
            )}
            id="expiration-date"
          ></div>
        </div>
        <div className="col-12 col-sm-6 form-group">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            htmlFor="cvv"
            className={classname(
              showError("cvv") && "form-error__label",
              "label--required"
            )}
          >
            Security Code/CVV2
          </label>
          {showError("cvv") && (
            <div className="form-error">Invalid Security Code/CVV2</div>
          )}
          <div
            className={classname(
              "form-control",
              showError("cvv") && "form-error"
            )}
            id="cvv"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default BrainTreeCCFields;
