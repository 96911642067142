import { RxMembershipSignUpProp } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/RxMembershipSignUp";
import { Field } from "@reactables/react-forms";
import PlanBtn from "./PlanBtn";
import { plans } from "@basicare/common/src/Constants/planTypes";
import PromoCodeDetails from "../Summary/PromoCodeDetails";

const Plans = ({
  rxMembershipSignUp,
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
}) => {
  const [state] = rxMembershipSignUp;
  const {
    applyPromoCode: { data: promoCode },
    applicationForm: {
      root: {
        value: {
          generalInformation: { planType: selectedPlanType },
        },
      },
    },
  } = state;
  return (
    <div className="row align-items-start">
      {plans
        .filter(({ planType }) => {
          if (promoCode) {
            return planType === selectedPlanType;
          } else {
            return true;
          }
        })
        .map((plan) => (
          <Field
            key={plan.planType}
            name="generalInformation.planType"
            component={PlanBtn}
            value={plan.planType}
            cols="4"
            plan={plan}
            newCost={promoCode?.newCost}
          />
        ))}
      {promoCode && (
        <div className="col">
          <PromoCodeDetails rxMembershipSignUp={rxMembershipSignUp} />
        </div>
      )}
    </div>
  );
};

export default Plans;
