import { RxBuilder } from "@jauntin/reactables";
import { Action } from "@reactables/core";
import { Observable } from "rxjs";

export const RxTouchSubmit = ({
  initialState = false,
  name = "rxTouchSubmit",
  sources = [],
}: {
  initialState?: boolean;
  name?: string;
  sources?: Observable<Action<unknown>>[];
} = {}) =>
  RxBuilder({
    name,
    initialState,
    sources,
    reducers: {
      touchSubmit: () => true,
      purchaseSuccess: () => false,
    },
  });
