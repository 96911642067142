import { Action } from "@reactables/core";
import { CustomReducers } from "@reactables/forms";
import { ApplyPromoCodeResponse } from "Features/MembershipSignUp/Models/promoCodes.model";
import { payment } from "../Configs/payment.config";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";

export const promoCodeReducers: CustomReducers<unknown> = {
  clearPromoCode: ({ updateValues, addControl }, state) => {
    state = updateValues(state, {
      controlRef: ["promoCode"],
      value: "",
    });

    if (!state.form["payment"]) {
      state = addControl(state, { controlRef: ["payment"], config: payment });
    }

    return state;
  },
  applyPromoCodeSuccess: (
    { updateValues, removeControl, addControl },
    state,
    {
      payload: { code, subscriptionInterval, type },
    }: Action<ApplyPromoCodeResponse>
  ) => {
    state = updateValues(state, {
      controlRef: ["promoCode"],
      value: code,
    });

    state = updateValues(state, {
      controlRef: ["generalInformation", "planType"],
      value: subscriptionInterval,
    });

    if (type === PromoCodeTypes.Trial) {
      state = state.form["payment"] ? removeControl(state, ["payment"]) : state;
    } else {
      state = state.form["payment"]
        ? state
        : addControl(state, { controlRef: ["payment"], config: payment });
    }

    return state;
  },
};
