import { classname } from "@jauntin/utilities";
import { ReactComponent as ChevronDown } from "@basicare/common/src/Assets/Images/chevron-down-solid.svg";
import { RxMembershipSignUpProp } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/RxMembershipSignUp";
import { getSelectedPlan } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/summary.selector";
import { currencyFromInt } from "@basicare/common/src/Helpers/CurrencyHelper";

const SummaryToggleBtn = ({
  rxMembershipSignUp,
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
}) => {
  const [state, actions] = rxMembershipSignUp;
  const selectedPlan = getSelectedPlan(state);
  return (
    <div className="summary-toggle-btn d-lg-none col-12">
      <div className="summary-toggle-btn__plan-text">
        Total{" "}
        {selectedPlan && (
          <span className="text-capitalize">{selectedPlan.planType} </span>
        )}
        Cost:
      </div>

      <div className="summary-toggle-btn__details">
        <div
          className={classname(
            `summary-toggle-btn__plan-price`,
            `summary-toggle-btn__plan-price--discounted`,
            `${selectedPlan?.planType || ""}`,
            state.applyPromoCode.data && "text-line-through c-primary-2"
          )}
        >
          {selectedPlan
            ? currencyFromInt(selectedPlan?.price).formatDollars()
            : "$ --"}
        </div>
        {state.applyPromoCode.data && (
          <div
            className={`summary-toggle-btn__plan-price ml-3 ${
              selectedPlan?.planType || ""
            }`}
          >
            {currencyFromInt(state.applyPromoCode.data.newCost).formatDollars()}
          </div>
        )}
        <button
          className="btn summary-toggle-btn__details-btn ml-auto"
          onClick={actions.summaryDrawerToggle.toggle}
        >
          View details{" "}
          <ChevronDown
            className={`summary-toggle-btn__chevron ${
              state.summaryDrawerOpen && "open"
            }`}
          />
        </button>
      </div>
    </div>
  );
};

export default SummaryToggleBtn;
