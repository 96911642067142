import { ActionMap, Reactable, Action } from "@reactables/core";
import {
  FormBuilders,
  RxToggle,
  RxRequest,
  RequestState,
} from "@jauntin/reactables";
import { combineLatest, from } from "rxjs";
import { mergeMap, map, tap } from "rxjs/operators";
import {
  ControlModels,
  RxFormActions,
  control,
  group,
  array,
} from "@reactables/forms";
import { addDependentReducer } from "@basicare/common/src/Rx/Reducers/addDependent.reducer";
import {
  Dependent,
  emptyDependent,
} from "@basicare/common/src/Models/dependent.model";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import RecaptchaService from "Services/RecaptchaService";
import ContactService from "Services/ContactService";
import { dependent } from "@basicare/common/src/Rx/Configs/dependent.config";
const { build } = FormBuilders;

export interface AddDependentsPayload {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dependents: Dependent[];
}

type AddDependentsFormState = ControlModels.Form<AddDependentsPayload>;

type AddDependentsFormActions = {
  addDependent: () => void;
} & RxFormActions &
  ActionMap;

const RxAddDependentsForm = () =>
  build(
    group({
      controls: {
        firstName: control({
          initialValue: "",
          validators: ["required"],
          normalizers: ["maxLength50"],
        }),
        lastName: control({
          initialValue: "",
          validators: ["required"],
          normalizers: ["maxLength50"],
        }),
        phone: control({
          initialValue: "",
          validators: ["required", "phoneNumber"],
          normalizers: ["normalizePhone"],
        }),
        email: control({
          initialValue: "",
          validators: ["required", "email"],
          normalizers: ["normalizeEmail"],
        }),
        cardholderId: control({
          initialValue: "",
          validators: ["required", "alphaNumeric"],
          normalizers: ["maxLength13", "alphaNumeric"],
        }),
        dependents: array({ controls: [dependent(emptyDependent)] }),
      },
    }),
    {
      name: "rxAddDependentsForm",
      reducers: {
        addDependent: addDependentReducer,
      },
      providers: formProviders,
    }
  ) as Reactable<AddDependentsFormState, AddDependentsFormActions>;

export type AddDependentsState = {
  form: AddDependentsFormState;
  submitTouched: boolean;
  dependentsSubmission: RequestState<unknown>;
};

export type AddDependentsActions = {
  form: AddDependentsFormActions;
  touchSubmit: () => void;
  submitDependents: (payload: AddDependentsPayload) => void;
};

export const RxAddDependents = ({
  recaptchaService,
  contactService,
  onSubmitSuccess,
}: {
  recaptchaService: RecaptchaService;
  contactService: ContactService;
  onSubmitSuccess?: () => void;
}): Reactable<AddDependentsState, AddDependentsActions> => {
  const [$form, formActions] = RxAddDependentsForm();

  const [submitTouched$, { toggleOn: touchSubmit }] = RxToggle();

  const [dependentsSubmission$, { send: submitDependents }] = RxRequest<
    AddDependentsPayload,
    unknown
  >({
    name: "rxDependentsSubmission",
    effect: ($submit) =>
      $submit.pipe(
        map(({ payload }: Action<AddDependentsPayload>) => {
          return from(recaptchaService.getToken()).pipe(
            mergeMap((captcha) => {
              return from(
                contactService.sendAddDependents({
                  ...payload,
                  captcha,
                })
              );
            }),
            tap(() => {
              onSubmitSuccess && onSubmitSuccess();
            })
          );
        })
      ),
  });

  const $state = combineLatest({
    form: $form,
    submitTouched: submitTouched$,
    dependentsSubmission: dependentsSubmission$,
  });

  const actions = {
    form: formActions,
    touchSubmit,
    submitDependents,
  };

  return [$state, actions];
};
