declare var grecaptcha: any;

class RecaptchaService {
  getToken() {
    return new Promise<string>((resolve, reject) => {
      if (!grecaptcha) {
        throw new Error("You have not loaded google recaptcha");
      }
      grecaptcha.ready(function () {
        grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: "submit",
          })
          .then(function (token: string) {
            resolve(token);
          }, reject);
      });
    });
  }
}

export default RecaptchaService;
