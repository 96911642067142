import { useEffect } from "react";
import { scrollToError } from "@jauntin/utilities";
import { TextInput } from "@jauntin/react-ui";
import { useAppStore } from "@reactables/react";
import { Link } from "react-router-dom";
import Button from "@basicare/common/src/Components/Themed/Button";
import AsyncButton from "@basicare/common/src/Components/AsyncButton";
import { Field } from "@reactables/react-forms";
import { AppState } from "Features/Shared/Rx/RxApp";
import StyledCheckboxField from "@basicare/common/src/Components/FormElements/CheckboxField/StyledCheckboxField";
import { RxMembershipSignUpProp } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/RxMembershipSignUp";
import BrainTreeCCFields from "Features/Shared/Components/BrainTreeCCFields";
import { ReactComponent as ChevronLeft } from "@basicare/common/src/Assets/Images/chevron-left-solid.svg";
import AddressFieldGroup from "@basicare/common/src/Components/FormElements/AddressFieldGroupNew";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Summary from "../Summary/Summary";
import { useHostedFields } from "Features/Shared/Hooks/useHostedFields";

import {
  getControl,
  getValue,
  getShowGroupActionButton,
} from "../../Rx/RxMembershipSignUp/Selectors/form.selector";
import { getFieldValidStates } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/payment.selector";
import { getPlanType } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/generalInformation.selector";
import { PlanTypes } from "@basicare/common/src/Constants/planTypes";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";
import { ExtendedMeta } from "@jauntin/utilities";

const Payment = ({
  rxMembershipSignUp,
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
}) => {
  const [state, actions] = rxMembershipSignUp;
  const [appState] = useAppStore<AppState>();

  const { paymentSubmitTouched } = state;

  const showActionButton = getShowGroupActionButton({
    state,
    submitTouched: paymentSubmitTouched,
  });

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || paymentSubmitTouched) && error);

  const hasPaymentControl = state.applicationForm["payment"] !== undefined;

  // Reset billing info on unmount to keep valid states in sync with braintree hosted fields
  useEffect(() => actions.applicationForm.resetCCFields, [actions]);

  const { tokenizer, bindHostedFields } = useHostedFields();

  const { applicationForm } = state;

  const fieldValidStates = getFieldValidStates(state);
  const planType = getPlanType(state);

  return (
    <div className="container container--fullwidth-mobile">
      <div className="page-heading">
        <h1>STEP 3: ENTER YOUR PAYMENT INFORMATION</h1>
      </div>
      <div className="page-wrapper">
        <div className="page-content">
          <Breadcrumbs />
          <div className="m-4"></div>
          <Summary rxMembershipSignUp={rxMembershipSignUp} fullScreen />
          {hasPaymentControl && (
            <>
              <section className="page-content__section">
                <h2 className="mb-1">Payment information</h2>
                <div className="mb-3">
                  You will be charged on{" "}
                  {planType === PlanTypes.Annual ? "an" : "a"} {planType} basis
                </div>
                <BrainTreeCCFields
                  onHostedFieldsLoaded={bindHostedFields}
                  fieldValidStates={fieldValidStates}
                  onValidityChange={actions.applicationForm.validateCCFields}
                  onBlur={actions.applicationForm.markAsCCFieldTouched}
                  enableShowErrors={paymentSubmitTouched}
                />
              </section>
              <Field
                name="payment.billing.sameAddressAsInsured"
                component={StyledCheckboxField}
                onChange={(e) => {
                  actions.applicationForm.selectSameAddressAsInsured(
                    e.currentTarget.checked
                  );
                }}
                label="Billing address is the same as the primary member"
              />

              {getControl(state, "payment.billing.differentAddressFields") && (
                <section className="page-content__section">
                  <h2 className="mb-3">Billing address information</h2>
                  <div className="row">
                    <div className="col-12 col-sm-6">
                      <Field
                        name="payment.billing.differentAddressFields.firstName"
                        component={TextInput}
                        label="First Name"
                        placeholder="First Name"
                        labelClassName="label--required"
                        lengthClassName="small"
                        showErrors={showErrors}
                        maxLength={50}
                      />
                    </div>
                    <div className="col-12 col-sm-6">
                      <Field
                        name="payment.billing.differentAddressFields.lastName"
                        component={TextInput}
                        label="Last Name"
                        labelClassName="label--required"
                        placeholder="Last Name"
                        lengthClassName="small"
                        showErrors={showErrors}
                        maxLength={50}
                      />
                    </div>
                  </div>
                  {appState?.usStates.data?.length && (
                    <AddressFieldGroup
                      googleMapsLoaded={appState?.googleMapsLoaded}
                      groupName="payment.billing.differentAddressFields.address"
                      usStates={appState.usStates.data}
                      addressValue={
                        getValue(
                          state,
                          "payment.billing.differentAddressFields.address.address"
                        ) as string
                      }
                      onClear={actions.applicationForm.clearBillingAddress}
                      onSelect={
                        actions.applicationForm.selectBillingAddressPlace
                      }
                      showErrors={showErrors}
                      fullWidth
                    />
                  )}
                </section>
              )}
              <Field
                name="payment.authorizationConsent"
                component={StyledCheckboxField}
                label="By confirming your subscription, you allow BasiCare Plus LLC to charge you now and for future payments in accordance with their terms."
                showErrors={showErrors}
              />
            </>
          )}
          <div className="page-content__section row">
            <Link to="/dependents" className="col-6 col-lg-auto pl-0">
              <Button
                outline
                className="w-100 d-flex justify-content-center align-items-center"
              >
                <ChevronLeft className="button__chevrone-right mr-1" /> Back
              </Button>
            </Link>
            <div className="col-6 col-lg-auto pr-0">
              {showActionButton ? (
                <AsyncButton
                  className="w-100"
                  onClick={() => {
                    actions.touchPaymentSubmit();
                    actions.purchase({
                      state,
                      tokenizer: hasPaymentControl ? tokenizer : null,
                    });
                  }}
                  spinning={state.purchase.loading}
                  disabled={!applicationForm.root.valid}
                >
                  Purchase
                </AsyncButton>
              ) : (
                <Button
                  className="w-100"
                  onClick={() => {
                    actions.touchPaymentSubmit();
                    scrollToError();
                  }}
                >
                  Purchase
                </Button>
              )}
            </div>

            {state.purchase.error && (
              <div className="col-12 form-error mt-3 pl-0">
                There was an error processing your request.
                <br />
                Please try again or contact us at{" "}
                <a className="text-underline" href={`mailto: ${BCX_EMAIL}`}>
                  {BCX_EMAIL}
                </a>{" "}
                or{" "}
                <a className="text-underline" href={`tel:${BCX_PHONE}`}>
                  {BCX_PHONE}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
