import { Card } from "react-bootstrap";
import { useReactable } from "@reactables/react";
import { Field } from "@reactables/react-forms";
import { ControlModels } from "@reactables/forms";
import { RadioInput, Button as AltButton } from "@jauntin/react-ui";
import { ExtendedMeta, classname } from "@jauntin/utilities";
import Button from "@basicare/common/src/Components/Themed/Button";
import SelectList from "@basicare/common/src/Components/FormElements/SelectList";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import DependentDobField from "@basicare/common/src/Components/FormElements/DependentDobField";

import { RelationshipType } from "@basicare/common/src/Constants/dependents";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import { genderOptions } from "@basicare/common/src/Constants/genders";
import { getLightModeSelectStyles } from "@basicare/common/src/Helpers/ReactSelectStyle";
import { RxTouchSubmit } from "Features/MembershipSignUp/Rx/RxTouchSubmit";

const DependentForm = ({
  dependentControl,
  dependentDobControl,
  save,
  cancel,
  className,
  disableSpouseRadio,
  editMode = false,
  hasVisibleErrors,
}: {
  dependentControl: ControlModels.FormControl<Dependent>;
  dependentDobControl: ControlModels.FormControl<string>;
  save: () => void;
  cancel: () => void;
  className?: string;
  disableSpouseRadio?: boolean;
  editMode?: boolean;
  hasVisibleErrors?: boolean;
}) => {
  const groupName = dependentControl.controlRef.join(".");
  const { relationshipToAccountHolder } = dependentControl.value;

  const [formSubmitTouched, { touchSubmit }] = useReactable(RxTouchSubmit, {
    initialState: editMode,
  });

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    (touched || formSubmitTouched) && error;

  const showDependentDobError = (meta: ExtendedMeta) =>
    showErrors(meta) ||
    (meta.touched && dependentControl.errors.dependentDateOfBirth);

  const showRelationshipError = Boolean(
    !dependentControl.value.relationshipToAccountHolder && formSubmitTouched
  );

  return (
    <Card className={classname("border-0 dependent-form", className)}>
      <Card.Body>
        <div
          className={classname(
            "label label--required",
            showRelationshipError && "form-error__label"
          )}
        >
          Relationship
        </div>
        {showRelationshipError && <div className="form-error">Required</div>}
        <div className="d-flex">
          <div className="pl-4 mr-5">
            <Field
              name={`${groupName}.relationshipToAccountHolder`}
              component={RadioInput}
              label="Spouse"
              value={RelationshipType.Spouse}
              disabled={disableSpouseRadio}
              checked={relationshipToAccountHolder === RelationshipType.Spouse}
            />
          </div>
          <div>
            <Field
              name={`${groupName}.relationshipToAccountHolder`}
              component={RadioInput}
              label="Dependent"
              value={RelationshipType.Dependent}
              checked={
                relationshipToAccountHolder === RelationshipType.Dependent
              }
            />
          </div>
        </div>
        <div className="row dependent-form__person-fields">
          <div className="col-sm-6">
            <Field
              name={`${groupName}.firstName`}
              component={TextField}
              label="First Name"
              placeholder="First Name"
              labelClassName="label--required"
              lengthClassName="small"
              showErrors={showErrors}
              maxLength={50}
            />
          </div>
          <div className="col-sm-6">
            <Field
              name={`${groupName}.lastName`}
              component={TextField}
              label="Last Name"
              placeholder="Last Name"
              labelClassName="label--required"
              lengthClassName="small"
              showErrors={showErrors}
              maxLength={50}
            />
          </div>
          <div className="col-sm-6">
            <Field
              name={`${groupName}.dateOfBirth`}
              component={DependentDobField}
              dependentControl={dependentControl}
              dependentDobControl={dependentDobControl}
              errorMessages={{
                dependentDateOfBirth: `Dependent must be less than 26 years old`,
              }}
              showErrors={showDependentDobError}
              inputMode="numeric"
              label="Date of Birth"
              placeholder="mm/dd/yyyy"
              labelClassName="label--required"
            />
          </div>
          <div className="col-sm-6">
            <Field
              name={`${groupName}.gender`}
              component={SelectList}
              placeholder="Gender"
              label="Gender"
              labelClassName="label--required"
              options={genderOptions}
              customStyles={getLightModeSelectStyles}
              searchable={false}
              showErrors={showErrors}
            />
          </div>
        </div>
        <div className="d-flex">
          {formSubmitTouched || dependentControl.valid || hasVisibleErrors ? (
            <Button
              variant="secondary"
              className="col-6 col-sm-auto"
              onClick={save}
              disabled={!dependentControl.valid}
              outline
            >
              Save Dependent
            </Button>
          ) : (
            <Button
              variant="secondary"
              className="col-6 col-sm-auto"
              onClick={touchSubmit}
              outline
            >
              Save Dependent
            </Button>
          )}

          <AltButton
            text="Cancel"
            className="col-6 col-sm-auto ml-sm-4 btn btn-link"
            onClick={cancel}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default DependentForm;
