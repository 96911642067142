import { group, control } from "@reactables/forms";

export const ccFieldsValidity = group({
  controls: {
    number: control([false, "required"]),
    cardholderName: control([false, "required"]),
    expirationDate: control([false, "required"]),
    cvv: control([false, "required"]),
  },
});
