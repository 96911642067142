import { useReactable } from "@reactables/react";
import { RxToggle } from "@jauntin/reactables";
import Logo from "@basicare/common/src/Assets/Images/basicare.1200.transparent.png";
import { Navbar, NavItem, Nav, NavDropdown, Modal } from "react-bootstrap";
import { BCX_PHONE, BCX_EMAIL } from "Constants/bcxContact";

const HeaderNav = ({
  referralParam = "",
  mobile = false,
}: {
  referralParam?: string;
  mobile?: boolean;
}) => (
  <Nav className="header__nav">
    <NavItem>
      <Nav.Link
        className="header__nav-link"
        href={`https://basicareplus.com/products/${referralParam}`}
      >
        Products
      </Nav.Link>
    </NavItem>
    <NavDropdown
      id={`bundles-dropdown-toggle${mobile ? "-mobile" : ""}`}
      title="Bundles"
      className="border-0"
    >
      <NavDropdown.Item
        className="header__nav-link header__nav-link--dropdown-item"
        href={`https://basicareplus.com/complete-enroll/${referralParam}`}
      >
        <NavDropdown.ItemText>Individual Bundles</NavDropdown.ItemText>
      </NavDropdown.Item>
      <NavDropdown.Item
        className="header__nav-link header__nav-link--dropdown-item"
        href={`https://basicareplus.com/${referralParam}#corpBundles`}
      >
        <NavDropdown.ItemText>Corporate Bundles</NavDropdown.ItemText>
      </NavDropdown.Item>
    </NavDropdown>
    <NavItem>
      <Nav.Link
        className="header__nav-link"
        href={`https://basicareplus.com/medications/${referralParam}`}
      >
        Medications
      </Nav.Link>
    </NavItem>
    <NavItem>
      <Nav.Link
        className="header__nav-link"
        href={`https://basicareplus.com/members/${referralParam}`}
      >
        Members
      </Nav.Link>
    </NavItem>
    <NavItem>
      <Nav.Link
        className="header__nav-link"
        href={`https://basicareplus.com/about-us/${referralParam}`}
      >
        About
      </Nav.Link>
    </NavItem>
  </Nav>
);

const Header = ({
  referralParam = "",
  facilityLogoUrl,
  facilityName,
}: {
  referralParam?: string;
  facilityLogoUrl?: string;
  facilityName?: string;
}) => {
  const [showMobileNav, { toggleOn: openMobileNav, toggleOff: hideMobileNav }] =
    useReactable(RxToggle);

  return (
    <>
      <header className="header">
        <div className="container-sm">
          <Navbar className="px-0" expand="lg">
            <Navbar.Brand>
              <a href={`https://basicareplus.com/${referralParam}`}>
                <img src={Logo} alt="Logo" className="img-fluid header__logo" />
              </a>
              {facilityLogoUrl && (
                <img
                  className="header__org-logo ml-auto ml-sm-3"
                  src={facilityLogoUrl}
                  alt={`${facilityName} Logo`}
                />
              )}
            </Navbar.Brand>
            <Navbar.Toggle
              className="header__hamburger"
              onClick={openMobileNav}
              aria-controls="responsive-navbar-nav"
            ></Navbar.Toggle>
            <div className="d-none d-lg-block">
              <HeaderNav referralParam={referralParam} />
            </div>
          </Navbar>
        </div>
      </header>
      <Modal
        dialogClassName="header__dialog"
        show={showMobileNav}
        onHide={hideMobileNav}
      >
        <div className="d-flex align-items-center">
          <a href={`https://basicareplus.com/${referralParam}`}>
            <img src={Logo} alt="Logo" className="img-fluid header__logo" />
          </a>
          {facilityLogoUrl && (
            <img
              className="header__org-logo ml-auto ml-sm-3"
              src={facilityLogoUrl}
              alt={`${facilityName} Logo`}
            />
          )}
          <button
            type="button"
            className="undecorate-btn d-flex ml-auto p-4 mr-1 font-20"
            onClick={hideMobileNav}
          >
            <i className="fal fa-times c-gray-600" />
          </button>
        </div>
        <HeaderNav referralParam={referralParam} mobile />
        <a
          className="text-decoration-none pl-2 mt-4"
          href={`mailto: ${BCX_EMAIL}`}
        >
          {BCX_EMAIL}
        </a>{" "}
        <a className="text-decoration-none pl-2" href={`tel:${BCX_PHONE}`}>
          {BCX_PHONE}
        </a>
      </Modal>
    </>
  );
};

export default Header;
