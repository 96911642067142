import { FormBuilders } from "@jauntin/reactables";
import { RxFormOptions, ControlModels, RxFormActions } from "@reactables/forms";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { form } from "./Configs/form.config";
import { Reactable, Action } from "@reactables/core";
import { ApplicationForm } from "Features/MembershipSignUp/Models/applicationForm.model";
import {
  generalInformationReducers,
  GeneralInformationActions,
} from "./Reducers/generalInformation.reducer";

import { paymentReducers, PaymentActions } from "./Reducers/payment.reducer";
import { promoCodeReducers } from "./Reducers/promoCode.reducer";
import {
  dependentsReducers,
  DependentsActions,
} from "./Reducers/dependents.reducer";
import { Observable } from "rxjs";

const { load, build } = FormBuilders;

export interface RxApplicationFormActions
  extends GeneralInformationActions,
    DependentsActions,
    PaymentActions,
    RxFormActions {}

/**
 * @description Manages form state of the application
 */
export const RxApplicationForm = ({
  initialState,
  sources = [],
}: {
  initialState: ControlModels.Form<ApplicationForm>;
  sources?: Observable<Action<unknown>>[];
}): Reactable<
  ControlModels.Form<ApplicationForm>,
  RxApplicationFormActions
> => {
  const formOptions: RxFormOptions = {
    name: "rxApplicationForm",
    sources,
    providers: formProviders,
    reducers: {
      purchaseSuccess: ({ resetControl }, state) => resetControl(state, []),
      ...generalInformationReducers,
      ...dependentsReducers,
      ...paymentReducers(),
      ...promoCodeReducers,
    },
  };

  if (initialState) {
    return load(initialState, formOptions) as Reactable<
      ControlModels.Form<ApplicationForm>,
      RxApplicationFormActions
    >;
  } else {
    return build(form, formOptions) as Reactable<
      ControlModels.Form<ApplicationForm>,
      RxApplicationFormActions
    >;
  }
};
