import { Reactable } from "@reactables/core";
import { RxToggle } from "@jauntin/reactables";
import { from, of, combineLatest, Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { RxRequest, RequestState, FormBuilders } from "@jauntin/reactables";
import PaymentService from "Services/PaymentService";
import { ControlModels, RxFormActions } from "@reactables/forms";
import { CardInfo } from "Features/MembershipSignUp/Models/createMembership.model";
import { ccFieldsValidity } from "Features/Shared/Rx/Configs/ccFieldsValidity.config";
import {
  ccFields,
  CCFieldActions,
} from "Features/Shared/Rx/Reducers/ccFields.reducer";
import { Tokenizer } from "Features/Shared/Hooks/useHostedFields";

interface CCFieldValidity {
  number: boolean;
  cardholderName: boolean;
  expirationDate: boolean;
  cvv: boolean;
}

export interface PaymentMethodUpdateState {
  submitTouched: boolean;
  verifyLinkRequest: RequestState<unknown>;
  submission: RequestState<unknown>;
  ccFieldsValidityForm: ControlModels.Form<CCFieldValidity>;
}

export type PaymentMethodUpdateActions = {
  touchSubmit: () => void;
  fieldValidity: CCFieldActions & RxFormActions;
  submit: (tokenizer: Tokenizer) => void;
};

export const RxPaymentMethodUpdate = ({
  link,
  paymentService,
}: {
  link: string;
  paymentService: PaymentService;
}): Reactable<PaymentMethodUpdateState, PaymentMethodUpdateActions> => {
  const [verifyLinkRequest$] = RxRequest<string, unknown>({
    name: "rxVerifyLinkRequest",
    sources: [of({ type: "send", payload: link })],
    effect: (action$) =>
      action$.pipe(
        map(({ payload }) =>
          from(paymentService.verifyPaymentMethodLink(payload))
        )
      ),
  });

  const [ccFieldsValidityForm$, fieldValidity] = FormBuilders.build(
    ccFieldsValidity,
    {
      reducers: ccFields(),
    }
  ) as Reactable<
    ControlModels.Form<CCFieldValidity>,
    CCFieldActions & RxFormActions
  >;

  const [submission$, { send: submit }] = RxRequest<Tokenizer, unknown>({
    name: "rxUpdatePaymentInfoSubmission",
    effect: (action$) =>
      action$.pipe(
        map(({ payload: tokenizer }) =>
          from(tokenizer()).pipe(
            mergeMap((tokenizedPayload) => {
              const {
                nonce,
                details: { lastFour, cardType, cardholderName: nameOnCard },
              } = tokenizedPayload;

              const cardInfo: CardInfo = {
                token: nonce,
                lastFour,
                cardType,
                nameOnCard,
              };
              return from(paymentService.updatePaymentMethod(link, cardInfo));
            })
          )
        )
      ),
  });

  const [submitTouched$, { toggleOn: touchSubmit }] = RxToggle();

  const state$: Observable<PaymentMethodUpdateState> = combineLatest({
    verifyLinkRequest: verifyLinkRequest$,
    ccFieldsValidityForm: ccFieldsValidityForm$,
    submission: submission$,
    submitTouched: submitTouched$,
  });

  const actions = {
    fieldValidity,
    submit,
    touchSubmit,
  };

  return [state$, actions];
};
