import {
  RelationshipType,
  MAX_DEPENDENTS,
} from "@basicare/common/src/Constants/dependents";
import { RxMembershipSignUpState } from "../RxMembershipSignUp";
import {
  getControl,
  getRootValue,
  getHasControlTouchError,
} from "./form.selector";

export const getIsSpouseSelected = (state: RxMembershipSignUpState) => {
  const {
    dependents: { dependentsList },
  } = getRootValue(state);

  return dependentsList.some(
    ({ dependent }) =>
      dependent.relationshipToAccountHolder === RelationshipType.Spouse
  );
};

export const getIsAddDependentDisabled = (state: RxMembershipSignUpState) => {
  const {
    dependents: { dependentsList, addDependentForm },
  } = getRootValue(state);

  return (
    dependentsList.length === MAX_DEPENDENTS ||
    dependentsList.some(({ confirmed }) => !confirmed) ||
    Boolean(addDependentForm)
  );
};

export const getDependentsList = (state: RxMembershipSignUpState) =>
  getRootValue(state).dependents.dependentsList;

export const getIsDependentsPageValid = (state: RxMembershipSignUpState) =>
  getControl(state, "dependents").valid;

export const getAddDependentShowingErrors = (
  state: RxMembershipSignUpState
) => {
  const hasControlTouchError = getHasControlTouchError(
    "dependents.addDependentForm"
  )(state);

  const dobControl = getControl(
    state,
    "dependents.addDependentForm.dateOfBirth"
  );

  const form = getControl(state, "dependents.addDependentForm");

  return (
    hasControlTouchError ||
    (dobControl.touched && form.errors.dependentDateOfBirth)
  );
};
