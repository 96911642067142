import { RxBuilder } from "@jauntin/reactables";
import { Action } from "@reactables/core";

export const RxIndex = (name: string = "rxIndex") =>
  RxBuilder({
    name,
    initialState: null,
    reducers: {
      setIndex: (_, { payload: index }: Action<number>) => index,
      clearIndex: () => null,
    },
  });
