import { classname } from "@jauntin/utilities";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import { Card } from "react-bootstrap";
import { toTitleCase } from "@basicare/common/src/Helpers/StringHelper";
import { Gender } from "@basicare/common/src/Constants/genders";

const DependentCard = ({
  dependent: {
    relationshipToAccountHolder,
    firstName,
    lastName,
    dateOfBirth,
    gender,
  },
  edit,
  remove,
  className,
  disabled = false,
}: {
  dependent: Dependent;
  edit?: () => void;
  remove?: () => void;
  className?: string;
  disabled?: boolean;
}) => {
  const hasControls = Boolean(edit && remove);

  return (
    <Card className={classname("dependent-card w-100 border-0", className)}>
      <Card.Body className="px-0">
        <div className="container px-3">
          <div className="row pl-3 dependent-card__row">
            {[
              {
                label: "Relationship",
                value: toTitleCase(relationshipToAccountHolder),
              },
              { label: "First Name", value: firstName },
              { label: "Last Name", value: lastName },
              { label: "Date of Birth", value: dateOfBirth },
              {
                label: "Gender",
                value: gender === Gender.Male ? "Male" : "Female",
              },
            ].map(({ label, value }) => (
              <div
                key={label}
                className={
                  hasControls
                    ? "col-6 col-md-2 col-lg-3 col-xl-2 px-0"
                    : "col-6 col-md"
                }
              >
                <div className="font-weight-bold">{label}</div>
                <div>{value}</div>
              </div>
            ))}
            <div className="dependent-card__controls">
              {edit && (
                <>
                  <button
                    className="btn btn-link p-0 mr-3"
                    disabled={disabled}
                    onClick={edit}
                  >
                    <i className="far fa-pen c-secondary" />
                  </button>
                </>
              )}
              {remove && (
                <>
                  <button
                    className="btn btn-link p-0"
                    disabled={disabled}
                    onClick={remove}
                  >
                    <i className="far fa-trash c-secondary" />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default DependentCard;
