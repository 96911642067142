import { Plan } from "@basicare/common/src/Models/plan.model";
import { RxMembershipSignUpState } from "../RxMembershipSignUp";
import { plans } from "@basicare/common/src/Constants/planTypes";
import { ApplicationForm } from "Features/MembershipSignUp/Models/applicationForm.model";
import { Gender } from "@basicare/common/src/Constants/genders";

interface Summary {
  "Subscription Interval"?: string;
  "Primary Member"?: string;
  "Date of Birth"?: string;
  Gender?: string;
  Address?: string;
  "Phone Number"?: string;
  "Email Address"?: string;
}

export const getSummary = (state: RxMembershipSignUpState): Summary => {
  const summary: Summary = {};

  const planTypeField = state.applicationForm["generalInformation.planType"];
  if (planTypeField.valid && planTypeField.value) {
    const planType = String(planTypeField.value);
    summary["Subscription Interval"] =
      planType.charAt(0).toUpperCase() + planType.slice(1);
  }
  const firstNameField = state.applicationForm["generalInformation.firstName"];
  const lastNameField = state.applicationForm["generalInformation.lastName"];
  const fullName = `${firstNameField.value} ${lastNameField.value}`.trim();

  if (fullName) {
    summary["Primary Member"] = fullName;
  }

  const dateOfBirthField =
    state.applicationForm["generalInformation.dateOfBirth"];
  if (dateOfBirthField.valid && dateOfBirthField.value) {
    summary["Date of Birth"] = String(dateOfBirthField.value);
  }

  const genderField = state.applicationForm["generalInformation.gender"];
  if (genderField.valid && genderField.value) {
    const gender = String(genderField.value);
    summary["Gender"] = gender === Gender.Male ? "Male" : "Female";
  }

  const addressLines = getAddressLines(state);
  if (addressLines) {
    summary["Address"] = addressLines;
  }

  const phoneNumberField =
    state.applicationForm["generalInformation.phoneNumber"];
  if (phoneNumberField.valid && phoneNumberField.value) {
    summary["Phone Number"] = String(phoneNumberField.value);
  }

  const emailField = state.applicationForm["generalInformation.email"];
  if (emailField.valid && emailField.value) {
    summary["Email Address"] = String(emailField.value);
  }

  return summary;
};

export const isSummaryEmpty = (state: RxMembershipSignUpState): boolean => {
  const summary = getSummary(state);

  return !Object.keys(summary).length;
};

export const getSelectedPlan = (
  state: RxMembershipSignUpState
): Plan | null => {
  const {
    generalInformation: { planType },
  } = state.applicationForm.root.value as ApplicationForm;

  return planType ? plans.find((plan) => plan.planType === planType) : null;
};

const getAddressLines = (state: RxMembershipSignUpState) => {
  const address = [];
  const address1Field = state.applicationForm["generalInformation.address"];
  if (address1Field.valid) {
    address.push(`${address1Field.value}`);
  }
  const address2Field = state.applicationForm["generalInformation.address2"];
  if (address2Field.value) {
    address.push(`${address2Field.value}`);
  }
  const cityField = state.applicationForm["generalInformation.city"];
  const stateField = state.applicationForm["generalInformation.state"];
  const zipField = state.applicationForm["generalInformation.zip"];
  let addressLine3 = "";
  if (cityField.valid) {
    addressLine3 += cityField.value + ", ";
  }
  if (stateField.valid) {
    addressLine3 += stateField.value + ", ";
  }
  if (zipField.valid) {
    addressLine3 += zipField.value;
  }
  if (addressLine3) {
    address.push(addressLine3);
  }
  if (address.length) {
    return address.join("\n");
  }

  return null;
};
