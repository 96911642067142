import { group, control } from "@reactables/forms";
import { generalInformation } from "./generalInformation.config";
import { payment } from "./payment.config";
import { dependents } from "./dependents.config";

export const form = group({
  controls: {
    generalInformation,
    dependents,
    payment,
    promoCode: control({
      initialValue: "",
      validators: ["maxLength20", "minLength3", "alphaNumericOrDash"],
      normalizers: ["alphaNumericOrDash", "maxLength20", "uppercase"],
    }),
  },
});
