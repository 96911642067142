import { ProducerInfoResponse } from "Features/MembershipSignUp/Models/producer.model";
import API from "Helpers/API";
import { AxiosResponse } from "axios";

class ProducerService {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public getProducerInfoByProducerCode(
    producerCode: string
  ): Promise<AxiosResponse<ProducerInfoResponse>> {
    return this.api.get({
      location: `/producers/byCode/${producerCode}`,
    });
  }
}

export default ProducerService;
