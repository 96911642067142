import {
  CreateMembershipPayload,
  CreateMembershipResponse,
  CardInfo,
} from "Features/MembershipSignUp/Models/createMembership.model";
import API from "../Helpers/API";
import { AxiosResponse } from "axios";

class PaymentService {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public createMembership(
    payload: CreateMembershipPayload
  ): Promise<AxiosResponse<CreateMembershipResponse>> {
    return this.api.post({
      location: "/membership",
      body: JSON.stringify(payload),
    });
  }

  public getMembershipDocs(url: string): Promise<any> {
    return this.api.getDownload({
      location: url,
      config: {
        responseType: "blob",
      },
    });
  }

  public getDocumentsStatus(subscriberNumber: string): Promise<any> {
    return this.api.get({
      location: "/membership/documents/status",
      params: { subscriberNumber },
    });
  }

  public verifyPaymentMethodLink(link: string): Promise<any> {
    return this.api.get({
      location: `/membership/payment-method/${link}`,
    });
  }

  public updatePaymentMethod(link: string, cardInfo: CardInfo): Promise<any> {
    return this.api.post({
      location: `/membership/payment-method/${link}`,
      body: { cardInfo },
    });
  }
}

export default PaymentService;
