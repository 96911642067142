import styled from "styled-components";
import { CheckboxInput } from "@jauntin/react-ui";

const activeColor = ({ theme }) => theme.components.CheckboxField?.activeColor;
const activeSecondaryColor = ({ theme }) =>
  theme.components.CheckboxField?.activeSecondaryColor;

const StyledCheckboxField = styled(CheckboxInput)`
  .custom-control-label {
    &::before,
    &::after {
      height: 20px;
      width: 20px;
      background-color: transparent;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: ${activeColor};
    background-color: ${activeColor};
  }

  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: ${activeColor};
  }

  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px ${activeColor};
  }

  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: ${activeSecondaryColor};
    border-color: ${activeSecondaryColor};
  }

  .custom-control-label {
    padding-left: 0.5rem;
    padding-top: 0.2rem;
    font-weight: normal;
  }

  input[type="checkbox"] {
    & + .form-error__label {
      color: #333;
    }

    & + .form-error__label.custom-control-label::before {
      border-color: #333;
    }
  }

  ${({ theme }) => theme.components.CheckboxField?.customCss}
`;

export default StyledCheckboxField;
