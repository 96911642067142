import { classname } from "@jauntin/utilities";
import { Field } from "@reactables/react-forms";
import { TextInput } from "@jauntin/react-ui";
import AsyncButton from "@basicare/common/src/Components/AsyncButton";
import PromoCodeDetails from "./PromoCodeDetails";
import { RxMembershipSignUpProp } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/RxMembershipSignUp";
import {
  isSummaryEmpty,
  getSummary,
  getSelectedPlan,
} from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/summary.selector";
import { getDependentsList } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/dependents.selector";
import { ReactComponent as EnvelopeIcon } from "@basicare/common/src/Assets/Images/envelope-icon.svg";
import { ReactComponent as PhoneIcon } from "@basicare/common/src/Assets/Images/phone-icon.svg";
import { currencyFromInt } from "@basicare/common/src/Helpers/CurrencyHelper";
import { getValue } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/Selectors/form.selector";
import { Gender } from "@basicare/common/src/Constants/genders";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";
import ModalConfirmation from "@basicare/common/src/Components/ModalConfirmation";
import {
  APPLY_PROMO_CODE_SUBSCRIPTION_INTERVAL_ERROR,
  ApplyPromoCodeError,
} from "Features/MembershipSignUp/Models/promoCodes.model";
import { PlanTypes } from "@basicare/common/src/Constants/planTypes";

const Summary = ({
  rxMembershipSignUp,
  fullScreen = false,
  close,
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
  fullScreen?: boolean;
  close?: () => void;
}) => {
  const [state, { applyPromoCode }] = rxMembershipSignUp;

  const isEmpty = isSummaryEmpty(state);
  const summary = getSummary(state);
  const selectedPlan = getSelectedPlan(state);

  const dependentsList = getDependentsList(state);

  const showSubscriptionIntervalErrorModal =
    (state.applyPromoCode.error as ApplyPromoCodeError)?.message ===
    APPLY_PROMO_CODE_SUBSCRIPTION_INTERVAL_ERROR;

  return (
    <div className={classname("summary", fullScreen && "summary--fullScreen")}>
      <div className="summary_wrapper">
        <div
          className={classname(
            "summary__header d-flex align-items-center",
            close && "summary__header--sticky"
          )}
        >
          <h2 className="mb-0">SUMMARY</h2>
          {fullScreen && (
            <div className="ml-auto d-none d-lg-block">
              <span className="bold-1 c-primary">
                Complete Care Bundle Subscription Interval:{" "}
              </span>
              {summary["Subscription Interval"]}
            </div>
          )}

          {close && (
            <button className="btn btn-link ml-auto py-0" onClick={close}>
              <i className="fas fa-chevron-down summary-drawer__arrow c-primary"></i>
            </button>
          )}
        </div>
        {fullScreen && (
          <>
            <div className="summary__line pt-4 mb-0 d-none d-lg-block">
              <div className="summary__line-title c-primary">
                Primary Member
              </div>
            </div>
          </>
        )}
        <div
          className={classname(
            "summary__primary-info",
            fullScreen && "summary__primary-info--fullScreen"
          )}
        >
          {isEmpty && (
            <div className="summary__text--empty">
              As you fill out the form, your order details will appear here.
            </div>
          )}
          {fullScreen ? (
            <>
              <div className="summary__line d-lg-none">
                <div className="summary__line-title">
                  <span className="bold-1 c-primary">
                    Complete Care Bundle Subscription Interval:{" "}
                  </span>
                  <span className="summary__line-text font-weight-normal">
                    {summary["Subscription Interval"]}
                  </span>
                </div>
              </div>
              <div className="summary__line d-lg-none">
                <div className="summary__line-title c-primary">
                  Primary Member
                </div>
              </div>
              <div className="summary__line row">
                <div className="col-6 col-xl-auto mw-xl--200">
                  <div className="summary__line-title">First Name</div>
                  <div className="summary__line-text">
                    {getValue(state, "generalInformation.firstName") as string}
                  </div>
                </div>
                <div className="col-6 col-xl-auto mw-xl--200">
                  <div className="summary__line-title">Last Name</div>
                  <div className="summary__line-text">
                    {getValue(state, "generalInformation.lastName") as string}
                  </div>
                </div>
              </div>
              <div className="summary__line row">
                <div className="col-6 col-lg-auto">
                  <div className="summary__line-title">Date of Birth</div>
                  <div className="summary__line-text">
                    {
                      getValue(
                        state,
                        "generalInformation.dateOfBirth"
                      ) as string
                    }
                  </div>
                </div>
                <div className="col-6 col-lg-auto">
                  <div className="summary__line-title">Gender</div>
                  <div className="summary__line-text">
                    {(getValue(
                      state,
                      "generalInformation.gender"
                    ) as string) === Gender.Male
                      ? "Male"
                      : "Female"}
                  </div>
                </div>
              </div>
              {Object.keys(summary)
                .filter((item, i) => i > 3)
                .map((item, i) => (
                  <div key={i} className="summary__line">
                    <div className="summary__line-title">{item}</div>
                    <div className="summary__line-text">{summary[item]}</div>
                  </div>
                ))}
            </>
          ) : (
            <>
              {Object.keys(summary).map((item, i) => (
                <div key={i} className="summary__line">
                  <div className="summary__line-title">{item}</div>
                  <div className="summary__line-text">{summary[item]}</div>
                </div>
              ))}
            </>
          )}
        </div>
        {dependentsList.length > 0 && (
          <div
            className={classname(
              "summary__dependents d-flex flex-column flex-xl-row flex-wrap",
              fullScreen ? "" : "d-lg-none"
            )}
          >
            {dependentsList.map(
              (
                {
                  dependent: {
                    relationshipToAccountHolder,
                    firstName,
                    lastName,
                    gender,
                    dateOfBirth,
                  },
                },
                index
              ) => (
                <div key={index} className="summary__dependent col col-xl-6">
                  <div className="summary__line-title c-primary mb-2">
                    {relationshipToAccountHolder}
                  </div>
                  <div className="summary__dependent-row row">
                    <div className="col-6 col-lg-3">
                      <div className="summary__line-title">First Name</div>
                      <div className="summary__line-text">{firstName}</div>
                    </div>
                    <div className="col-6 col-lg-3">
                      <div className="summary__line-title">Last Name</div>
                      <div className="summary__line-text">{lastName}</div>
                    </div>
                    <div className="col-6 col-lg-3">
                      <div className="summary__line-title">Date of Birth</div>
                      <div className="summary__line-text">{dateOfBirth}</div>
                    </div>
                    <div className="col-6 col-lg-3">
                      <div className="summary__line-title">Gender</div>
                      <div className="summary__line-text">
                        {gender === Gender.Male ? "Male" : "Female"}
                      </div>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
        <div
          className={classname(
            "summary__promo-code",
            fullScreen && "summary__promo-code--fullScreen"
          )}
        >
          <div className="summary__promo-code-input">
            <Field
              name="promoCode"
              component={TextInput}
              errorMessages={{
                minLength3: "Must be at least 3 characters",
              }}
              label={
                <>
                  <i className="fa fa-gift-card c-secondary" /> Promo Code
                </>
              }
              readOnly={Boolean(state.applyPromoCode.data)}
              components={{
                inputAppend: state.applyPromoCode.data ? null : (
                  <AsyncButton
                    className="border-left-radius-0 form-control d-flex align-items-center"
                    spinning={state.applyPromoCode.loading}
                    disabled={
                      !state.applicationForm["promoCode"].valid ||
                      !state.applicationForm.root.value.promoCode
                    }
                    onClick={() =>
                      applyPromoCode.send({
                        code: state.applicationForm.root.value.promoCode,
                        subscriptionInterval:
                          state.applicationForm.root.value.generalInformation
                            .planType,
                      })
                    }
                  >
                    Apply
                  </AsyncButton>
                ),
              }}
            />
            {state.applyPromoCode.error && (
              <div className="form-error">
                {(state.applyPromoCode.error as ApplyPromoCodeError).message}
              </div>
            )}
          </div>
          {state.applyPromoCode.data && (
            <PromoCodeDetails rxMembershipSignUp={rxMembershipSignUp} />
          )}
        </div>
        <div
          className={classname(
            "summary__selected-plan",
            fullScreen && "summary__selected-plan--fullScreen"
          )}
        >
          <div className="summary__plan-text">
            Total{" "}
            {selectedPlan && (
              <span className="text-capitalize">{selectedPlan.planType} </span>
            )}
            Cost:
          </div>
          <div className="d-flex flex-column align-items-end flex-md-row align-items-md-center ml-auto">
            <div
              className={classname(
                `summary__plan-price`,
                `${selectedPlan?.planType || ""}`,
                state.applyPromoCode.data &&
                  "text-line-through c-primary-2 summary__plan-price--discounted",
                "order-1 order-md-0"
              )}
            >
              {selectedPlan
                ? currencyFromInt(selectedPlan?.price).formatDollars()
                : "$ --"}
            </div>
            {state.applyPromoCode.data && (
              <div
                className={`summary__plan-price order-0 ml-3 ${
                  selectedPlan?.planType || ""
                }`}
              >
                {currencyFromInt(
                  state.applyPromoCode.data.newCost
                ).formatDollars()}
              </div>
            )}
          </div>
        </div>
        <div className="summary__footer">
          <div className="summary__footer-line mb-3">
            <div className="summary__icon-circle">
              <EnvelopeIcon />
            </div>
            <div>
              Message us at <a href={`mailto:${BCX_EMAIL}`}>{BCX_EMAIL}</a>
            </div>
          </div>
          <div className="summary__footer-line">
            <div className="summary__icon-circle">
              <PhoneIcon />
            </div>
            <div>
              Or call us at <a href={`tel:${BCX_PHONE}`}>{BCX_PHONE}</a>
            </div>
          </div>
        </div>
      </div>
      {showSubscriptionIntervalErrorModal && (
        <ModalConfirmation
          show={showSubscriptionIntervalErrorModal}
          message={(() => {
            const {
              promoCode: { subscriptionInterval },
              message,
            } = state.applyPromoCode.error as ApplyPromoCodeError;
            return (
              <>
                {message} Would you like to choose{" "}
                {subscriptionInterval === PlanTypes.Annual ? "an" : "a"}{" "}
                <span className="font-weight-bold">{subscriptionInterval}</span>{" "}
                subscription to use this promo code?
              </>
            );
          })()}
          isUpdating={state.applyPromoCode.loading}
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnClassName="w--70"
          cancelBtnClassName="btn btn-outline-primary font-weight-bold border-radius-4 w--70"
          handleClose={applyPromoCode.resetState}
          requireCheckbox={false}
          action={() =>
            applyPromoCode.send({
              code: state.applicationForm.root.value.promoCode,
            })
          }
        />
      )}
    </div>
  );
};

export default Summary;
