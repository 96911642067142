import { useReactable } from "@reactables/react";
import { ExtendedMeta } from "@jauntin/utilities";
import { Form, Field, FormArray, ControlModels } from "@reactables/react-forms";
import { Row, Col } from "react-bootstrap";
import { RxAddDependents } from "../Rx/RxAddDependents";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import { PhoneInput, EmailInput } from "@jauntin/react-ui";
import DependentFields from "@basicare/common/src/Components/FormElements/DependentFields";
import {
  MAX_DEPENDENTS,
  RelationshipType,
} from "@basicare/common/src/Constants/dependents";
import { getCustomStyles } from "@basicare/common/src/Helpers/ReactSelectStyle";
import Button from "@basicare/common/src/Components/Themed/Button";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import { getShowActionBtn } from "../Rx/Selectors/addDependents.selector";
import { useScript } from "@basicare/common/src/Hooks/useScript";
import {
  IFRAME_RESIZER_CHILD_SCRIPT,
  RECAPTCHA_SCRIPT,
} from "Constants/vendorScriptUrls";
import RecaptchaService from "Services/RecaptchaService";
import ContactService from "Services/ContactService";
import API from "Helpers/API";
import TwoStageButton from "@basicare/common/src/Components/TwoStageButton";
import { IFramePage } from "iframe-resizer";

declare global {
  interface Window {
    parentIFrame?: IFramePage;
  }
}

const AddDependents = () => {
  useScript(IFRAME_RESIZER_CHILD_SCRIPT);
  useScript(RECAPTCHA_SCRIPT);

  const [state, actions] = useReactable(RxAddDependents, {
    recaptchaService: new RecaptchaService(),
    contactService: new ContactService(new API()),
    onSubmitSuccess: () => {
      if ("parentIFrame" in window) {
        setTimeout(() => {
          window.parentIFrame.scrollToOffset(0, 0);
        }, 500);
      }
    },
  });

  if (!state) return;

  const { form, submitTouched } = state;

  const { dependents } = form.root.value;
  const disableSpouseRadio = dependents.some(
    ({ relationshipToAccountHolder }) =>
      relationshipToAccountHolder === RelationshipType.Spouse
  );

  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || submitTouched) && error);

  const showActionBtn = getShowActionBtn(state);

  if (state.dependentsSubmission.success) {
    return (
      <div className="page-content px-3 mh--300 d-flex justify-content-center align-items-center">
        <h1>
          Thank you. We will be in touch once your dependent request is
          processed.
        </h1>
      </div>
    );
  }

  return (
    <div className="add-dependents page-content">
      <Form rxForm={[form, actions.form]}>
        <h1 className="text-center font-weight-bold mb-4">
          Your Personal Information
        </h1>
        <Row className="mb-5">
          <Col sm={6}>
            <Field
              name="firstName"
              component={TextField}
              label="First Name"
              placeholder="First Name"
              labelClassName="label--required"
              lengthClassName="small"
              showErrors={showErrors}
              maxLength={50}
            />
          </Col>
          <Col sm={6}>
            <Field
              name="lastName"
              component={TextField}
              label="Last Name"
              placeholder="Last Name"
              labelClassName="label--required"
              lengthClassName="small"
              showErrors={showErrors}
              maxLength={50}
            />
          </Col>
          <Col sm={6}>
            <Field
              name="phone"
              component={PhoneInput}
              label="Phone Number"
              placeholder="XXX-XXX-XXXX"
              labelClassName="label--required"
              showErrors={showErrors}
            />
          </Col>
          <Col sm={6}>
            <Field
              name="email"
              component={EmailInput}
              label="Email Address"
              placeholder="your@gmail.com"
              labelClassName="label--required"
              showErrors={showErrors}
            />
          </Col>
          <Col sm={6}>
            <Field
              name="cardholderId"
              component={TextField}
              label="Card holder ID from your card"
              placeholder="Card holder ID from your card"
              labelClassName="label--required"
              showErrors={showErrors}
            />
          </Col>
        </Row>
        <FormArray name="dependents">
          {({ items, removeControl }) => {
            return (
              <>
                {items.map(
                  (
                    dependentControl: ControlModels.FormControl<Dependent>,
                    index
                  ) => (
                    <div className="my-5" key={dependentControl.key}>
                      <div className="d-flex">
                        <h1 className="text-center mx-auto font-weight-bold mb-4">
                          Dependent {index + 1}
                        </h1>
                        {dependents.length > 1 && (
                          <button
                            className="add-dependents__remove float-right font-weight-bold btn btn-link position-absolute right-0"
                            onClick={() => removeControl(index)}
                          >
                            <i className="fal fa-trash mr-2" />
                            <span className="d-none d-sm-inline">
                              Remove Dependent
                            </span>
                          </button>
                        )}
                      </div>
                      <DependentFields
                        dependentControl={dependentControl}
                        dependentDobControl={
                          form[
                            `dependents.${index}.dateOfBirth`
                          ] as ControlModels.FormControl<string>
                        }
                        groupName={`dependents.${index}`}
                        disableSpouseRadio={disableSpouseRadio}
                        relationshipControl={
                          form[
                            `dependents.${index}.relationshipToAccountHolder`
                          ] as ControlModels.FormControl<RelationshipType>
                        }
                        customStyles={getCustomStyles}
                        showErrors={showErrors}
                      />
                    </div>
                  )
                )}
                <div className="add-dependents__action-btns">
                  <Button
                    outline
                    disabled={dependents.length === MAX_DEPENDENTS}
                    onClick={actions.form.addDependent}
                  >
                    Add More Dependents
                  </Button>
                  <TwoStageButton
                    action={() =>
                      actions.submitDependents(state.form.root.value)
                    }
                    actionEnabled={showActionBtn}
                    spinning={state.dependentsSubmission.loading}
                    touchSubmit={actions.touchSubmit}
                    disableAction={!form.root.valid}
                    text={
                      <>
                        Submit <i className="ml-2 far fa-arrow-right" />
                      </>
                    }
                  />
                </div>
                {state.dependentsSubmission.error && (
                  <div className="form-error d-flex w-100 justify-content-end mt-3">
                    There was an error, please try again
                  </div>
                )}
              </>
            );
          }}
        </FormArray>
      </Form>
    </div>
  );
};

export default AddDependents;
