import Button from "@basicare/common/src/Components/Themed/Button";
import { ReactComponent as ChevronRight } from "@basicare/common/src/Assets/Images/chevron-right-solid.svg";

const NextButton = (props: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Button {...props}>
    Next Step <ChevronRight className="button__chevrone-right" />
  </Button>
);

export default NextButton;
