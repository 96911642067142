import { Action } from "@reactables/core";
import { RxProducerReferralState } from "../RxProducerReferral";

export const producerReferralSuccess = (
  state: RxProducerReferralState,
  {
    payload: { producerCode, producerLogoUrl },
  }: Action<{
    producerCode: string;
    producerLogoUrl?: string;
  }>
): RxProducerReferralState => ({
  producerCode,
  producerLogoUrl,
});
