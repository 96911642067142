import { control, group } from "@reactables/forms";
import { Configs } from "@jauntin/reactables";

export const differentAddressFields = group({
  controls: {
    firstName: control({
      initialValue: "",
      validators: ["required"],
      normalizers: ["maxLength50"],
    }),
    lastName: control({
      initialValue: "",
      validators: ["required"],
      normalizers: ["maxLength50"],
    }),
    address: group({
      controls: {
        ...Configs.address2Group().controls,
        zip: control({
          initialValue: "",
          validators: ["required", "zipCode"],
          normalizers: ["normalizeZip"],
        }),
      },
    }),
  },
});
