import { useEffect, useCallback, useRef } from "react";
import { HostedFields, HostedFieldsTokenizePayload } from "braintree-web";

export type Tokenizer = () => Promise<HostedFieldsTokenizePayload>;

export const useHostedFields = () => {
  const hostedFieldsRef = useRef<HostedFields>();

  useEffect(() => {
    return () => {
      hostedFieldsRef.current.teardown();
    };
  }, []);

  const bindHostedFields = useCallback((fields: HostedFields) => {
    hostedFieldsRef.current = fields;
  }, []);

  const tokenizer = () =>
    new Promise<HostedFieldsTokenizePayload>((resolve, reject) => {
      hostedFieldsRef.current.tokenize((err, payload) => {
        if (err) {
          reject(err);
        } else {
          resolve(payload);
        }
      });
    });

  return { bindHostedFields, tokenizer };
};
