import { AddDependentsPayload } from "Features/AddDependents/Rx/RxAddDependents";
import { ContactUsPayload } from "Features/ContactUs/Rx/RxContactUs";

class ContactService {
  api = null;

  constructor(api) {
    this.api = api;
  }

  sendContactForm(body: ContactUsPayload & { captcha: string }) {
    return this.api.post({
      location: "/contactUs",
      body,
    });
  }

  sendAddDependents(body: AddDependentsPayload & { captcha: string }) {
    return this.api.post({
      location: "/contactUs/addDependents",
      body,
    });
  }
}

export default ContactService;
