import { RxMembershipSignUpState } from "../RxMembershipSignUp";
import { getQueryString } from "@basicare/common/src/Helpers/getQueryString";

export const getReferralQueryString = (
  state: RxMembershipSignUpState
): string => {
  const facilityCode = state.facilityReferral?.facilityCode;
  const producerCode = state.producerReferral?.producerCode;

  const queryParams = {
    org: facilityCode,
    pro: producerCode,
  };

  return getQueryString(queryParams);
};
