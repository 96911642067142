import { RxMembershipSignUpState } from "../RxMembershipSignUp";
import { MonoTypeOperatorFunction, of } from "rxjs";
import formProviders from "@basicare/common/src/Helpers/formProviders";
import { resetFormControls } from "@jauntin/reactables";
import { tap, map } from "rxjs/operators";

/**
 * @description reads the updated state, clears sensitive info and stores state in storage
 */
export const storageEffect: (storage: {
  setItem: (name: string, value: unknown) => void;
}) => MonoTypeOperatorFunction<RxMembershipSignUpState> =
  (storage) => (state$) =>
    state$.pipe(
      tap((state) => {
        if (state.applicationForm["payment"]) {
          of(state.applicationForm)
            .pipe(
              resetFormControls([["payment"]], formProviders),
              map((rxFormState) => {
                return {
                  ...state,
                  applicationForm: rxFormState,
                };
              })
            )
            .subscribe((clearedState) => {
              storage.setItem("state", clearedState);
            });
        } else {
          storage.setItem("state", state);
        }
      })
    );
