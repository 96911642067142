import { of } from "rxjs";
import { RxBuilder } from "@jauntin/reactables";
import { producerReferralSuccess } from "./Reducers/producerReferralSuccess.reducer";
import { handleProducerReferral } from "./Operators/producer.operator";
import ProducerService from "Services/ProducerService";

export interface RxProducerReferralState {
  producerCode: string;
  producerLogoUrl?: string;
}

export const RxProducerReferral = ({
  producerService,
  initialState = {
    producerCode: "",
    producerLogoUrl: null,
  } as RxProducerReferralState,
  referralParams = "",
}: {
  producerService: ProducerService;
  initialState: RxProducerReferralState;
  referralParams: string;
}) =>
  RxBuilder({
    name: "rxProducerReferral",
    sources: [of(referralParams).pipe(handleProducerReferral(producerService))],
    initialState,
    reducers: {
      producerReferralSuccess,
    },
  });
