import { Action, ActionMap } from "@reactables/core";
import { CustomReducers } from "@reactables/forms";
import { dependentsArrayControl } from "../Configs/dependents.config";
import { ApplicationForm } from "Features/MembershipSignUp/Models/applicationForm.model";
import {
  RelationshipType,
  MAX_DEPENDENTS,
} from "@basicare/common/src/Constants/dependents";
import { emptyDependent } from "@basicare/common/src/Models/dependent.model";
import { dependent as dependentConfig } from "@basicare/common/src/Rx/Configs/dependent.config";

export interface DependentsActions extends ActionMap {
  addDependent: () => void;
  saveNewDependent: () => void;
  cancelNewDependent: () => void;

  editDependent: (index: number) => void;
  saveDependentEdit: (index: number) => void;
  cancelDependentEdit: (index: number) => void;
  removeDependent: (index: number) => void;
}

export const dependentsReducers: CustomReducers<DependentsActions> = {
  addDependent: ({ addControl }, state) => {
    const {
      dependents: { dependentsList },
    } = state.form.root.value as ApplicationForm;

    if (dependentsList.length === MAX_DEPENDENTS) {
      return state;
    }

    const isSpouseSelected = dependentsList.some(
      ({ dependent }) =>
        dependent.relationshipToAccountHolder === RelationshipType.Spouse
    );

    state = addControl(state, {
      controlRef: ["dependents", "addDependentForm"],
      config: dependentConfig({
        ...emptyDependent,
        relationshipToAccountHolder: isSpouseSelected
          ? RelationshipType.Dependent
          : "",
      }),
    });

    return state;
  },
  saveNewDependent: ({ pushControl, removeControl }, state) => {
    const {
      dependents: { addDependentForm },
    } = state.form.root.value as ApplicationForm;

    state = pushControl(state, {
      controlRef: ["dependents", "dependentsList"],
      config: dependentsArrayControl(addDependentForm),
    });

    state = removeControl(state, ["dependents", "addDependentForm"]);

    return state;
  },
  cancelNewDependent: ({ removeControl }, state) => {
    state = removeControl(state, ["dependents", "addDependentForm"]);
    return state;
  },
  editDependent: (
    { updateValues },
    state,
    { payload: index }: Action<number>
  ) => {
    state = updateValues(state, {
      controlRef: ["dependents", "dependentsList", index, "confirmed"],
      value: false,
    });

    return state;
  },
  saveDependentEdit: (
    { updateValues, markControlAsPristine },
    state,
    { payload: index }: Action<number>
  ) => {
    state = updateValues(state, {
      controlRef: ["dependents", "dependentsList", index, "confirmed"],
      value: true,
    });

    state = markControlAsPristine(state, [
      "dependents",
      "dependentsList",
      index,
    ]);

    return state;
  },
  cancelDependentEdit: (
    { updateValues },
    state,
    { payload: index }: Action<number>
  ) => {
    const controlRef = ["dependents", "dependentsList", index];

    state = updateValues(state, {
      controlRef,
      value: state.form[controlRef.join(".")].pristineValue,
    });

    return state;
  },
  removeDependent: (
    { removeControl },
    state,
    { payload: index }: Action<number>
  ) => {
    state = removeControl(state, ["dependents", "dependentsList", index]);

    return state;
  },
};
