import { group, control } from "@reactables/forms";
import { ccFieldsValidity } from "Features/Shared/Rx/Configs/ccFieldsValidity.config";

export const payment = group({
  controls: {
    billing: group({
      controls: {
        ccFieldsValidity,
        sameAddressAsInsured: control([true]),
        // differentAddressFields added here if sameAddressAsContact is false
      },
    }),
    authorizationConsent: control([false, "required"]),
  },
});
