import { NavLink } from "react-router-dom";
import { Breadcrumb as IBreadcrumb } from "../../Models/breadcrumb.model";

const Body = ({
  step,
  text,
  hasArrow = false,
}: {
  step: number;
  text: string;
  hasArrow?: boolean;
}) => (
  <>
    <div className="breadcrumb__entry">
      <div className="breadcrumb-content">
        <div
          className={`breadcrumb-content__step ${
            step === 1 ? "breadcrumb-content__step--fill" : ""
          }`}
        >
          <div className="breadcrumb-content__step-number">{step}</div>
        </div>
        {hasArrow && <div className="breadcrumb breadcrumb__arrow"></div>}
      </div>
      <div className={`breadcrumb__text`}>{text}</div>
    </div>
  </>
);

const Breadcrumb = ({
  step,
  text,
  disabled,
  link,
  isActive = false,
  disableNavigation = false,
  hasArrow = false,
}: {
  disabled: boolean;
  currentStep: number;
  isActive?: boolean;
  disableNavigation?: boolean;
  hasArrow?: boolean;
} & IBreadcrumb) => {
  const wrapperClassName = `breadcrumb ${disabled ? "disabled" : ""} ${
    disableNavigation ? "cursor-default" : ""
  } `;

  return (
    <>
      {disabled || disableNavigation ? (
        <div className={wrapperClassName + ` ${isActive ? "active" : ""}`}>
          <Body step={step} text={text} hasArrow={hasArrow} />
        </div>
      ) : (
        <>
          <NavLink
            to={link}
            className={wrapperClassName}
            isActive={() => isActive}
            activeClassName="active"
          >
            <Body step={step} text={text} hasArrow={hasArrow} />
          </NavLink>
        </>
      )}
    </>
  );
};

export default Breadcrumb;
