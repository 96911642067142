import { RxContactUs } from "../Rx/RxContactUs";
import { useReactable } from "@reactables/react";
import { Form, Field } from "@reactables/react-forms";
import TextField from "@basicare/common/src/Components/FormElements/TextField";
import { EmailInput, TextArea } from "@jauntin/react-ui";
import { ExtendedMeta } from "@jauntin/utilities";
import { hasControlTouchError } from "@basicare/common/src/Rx/Selectors/hasControlTouchError.selector";
import { useScript } from "@basicare/common/src/Hooks/useScript";
import {
  IFRAME_RESIZER_CHILD_SCRIPT,
  RECAPTCHA_SCRIPT,
} from "Constants/vendorScriptUrls";
import ContactService from "Services/ContactService";
import API from "Helpers/API";
import RecaptchaService from "Services/RecaptchaService";
import { BCX_PHONE } from "Constants/bcxContact";
import TwoStageButton from "@basicare/common/src/Components/TwoStageButton";
import { IFramePage } from "iframe-resizer";

declare global {
  interface Window {
    parentIFrame?: IFramePage;
  }
}

const ContactUs = () => {
  useScript(IFRAME_RESIZER_CHILD_SCRIPT);
  useScript(RECAPTCHA_SCRIPT);
  const [state, actions] = useReactable(RxContactUs, {
    recaptchaService: new RecaptchaService(),
    contactService: new ContactService(new API()),
    onSubmitSuccess: () => {
      if ("parentIFrame" in window) {
        setTimeout(() => {
          window.parentIFrame.scrollToOffset(0, 0);
        }, 500);
      }
    },
  });
  if (!state) return;

  const { submitTouched } = state;
  const showErrors = ({ touched, error }: ExtendedMeta) =>
    Boolean((touched || submitTouched) && error);
  const showActionBtn =
    state.form.root.valid || submitTouched || hasControlTouchError(state.form);

  if (state.contactUsSubmission.success) {
    return (
      <div className="contact-us page-content mh--300 d-flex justify-content-center align-items-center">
        <h1>
          Thank you. Your submission has been sent. For immediate assistance,
          please call {BCX_PHONE} during business hours.
        </h1>
      </div>
    );
  }

  return (
    <div className="contact-us page-content">
      <Form rxForm={[state.form, actions.form]}>
        <Field
          name="name"
          labelClassName="label--required"
          placeholder="Your Name"
          label="Name"
          component={TextField}
          showErrors={showErrors}
        />
        <Field
          name="company"
          label="Company"
          placeholder="Your Company"
          component={TextField}
          showErrors={showErrors}
        />
        <Field
          name="email"
          label="Email"
          labelClassName="label--required"
          placeholder="your@gmail.com"
          component={EmailInput}
          showErrors={showErrors}
        />
        <Field
          name="message"
          label="Message"
          labelClassName="label--required"
          placeholder="Write a message..."
          component={TextArea}
          showErrors={showErrors}
        />
        <div className="mt-4 d-flex justify-content-end">
          <TwoStageButton
            className="w--170"
            actionEnabled={showActionBtn}
            action={() => actions.submit(state.form.root.value)}
            spinning={state.contactUsSubmission.loading}
            disableAction={!state.form.root.valid}
            text={
              <>
                Submit <i className="ml-2 far fa-arrow-right" />
              </>
            }
            touchSubmit={actions.touchSubmit}
          />
        </div>
        {state.contactUsSubmission.error && (
          <div className="form-error d-flex w-100 justify-content-end mt-3">
            There was an error, please try again
          </div>
        )}
      </Form>
    </div>
  );
};

export default ContactUs;
