import { Action } from "@reactables/core";
import { RxFacilityReferralState } from "../RxFacilityReferral";

export const facilityReferralSuccess = (
  state: RxFacilityReferralState,
  {
    payload: { facilityCode, facilityName, facilityLogoUrl },
  }: Action<{
    facilityCode: string;
    facilityName: string;
    facilityLogoUrl?: string;
  }>
): RxFacilityReferralState => ({
  facilityCode,
  facilityName,
  facilityLogoUrl,
});
