import Button from "@basicare/common/src/Components/Themed/Button";
import { Link } from "react-router-dom";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";

const OopsMessage = () => {
  return (
    <>
      <h1 className="text-uppercase mb-4">Oops!</h1>
      <h5 className="mb-4">Are you looking for an expired page?</h5>
      <p className="mb-4 text-center">
        If you recently created a membership,
        <br />
        check your email for your membership card and activation instructions.
      </p>

      <Link to="/">
        <Button>Sign Up!</Button>
      </Link>

      <p className="mt-4 text-center">
        If you have any questions, please contact us
        <br />
        at <a href={`mailto:${BCX_EMAIL}`}>{BCX_EMAIL}</a> or{" "}
        <a href={`tel:${BCX_PHONE}`}>{BCX_PHONE}</a>
      </p>
    </>
  );
};

export default OopsMessage;
