import "./App.scss";
import { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useAppStore } from "@reactables/react";
import { ThemeProvider } from "styled-components";
import { BCX_THEME } from "@basicare/common/src/Constants/themes/bcxTheme";
import { Libraries, useJsApiLoader } from "@react-google-maps/api";
import { AppState } from "Features/Shared/Rx/RxApp";
import MembershipSignUp from "Features/MembershipSignUp/Components/MembershipSignUp";
import AddDependents from "Features/AddDependents/Components/AddDependents";
import ContactUs from "Features/ContactUs/Components/ContactUs";
import PaymentMethodUpdate from "Features/PaymentMethodUpdate/Components/PaymentMethodUpdate";

const libraries: Libraries = ["places"];

const App = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
    language: "en",
  });

  const [appState, appActions] = useAppStore<AppState>();

  useEffect(() => {
    if (isLoaded) {
      appActions.googleMapsLoaded();
    }
  }, [isLoaded, appActions]);

  if (!appState) return;

  return (
    <>
      <ThemeProvider theme={BCX_THEME}>
        <Switch>
          <Route
            path="/payment-method-update/:id"
            component={PaymentMethodUpdate}
          />
          <Route path="/contact-us" component={ContactUs} />
          <Route path="/add-dependents" component={AddDependents} />
          <Route path="/" component={MembershipSignUp} />
        </Switch>
      </ThemeProvider>
    </>
  );
};

export default App;
