import { Action } from "@reactables/core";
import FacilityService from "Services/FacilityService";
import { EMPTY, from, OperatorFunction } from "rxjs";
import { catchError, filter, map, mergeMap } from "rxjs/operators";
import { FacilityInfoResponse } from "../../../Models/facility.model";

const FACILITY_CODE_PARAM = "org";

/**
 * @description Provided a query string:
 * Operator looks for a facility code param and looks up Facility info
 */
export const handleFacilityReferral =
  (
    facilityService: FacilityService,
    onFacilityReferralError
  ): OperatorFunction<
    string,
    Action<{
      facilityCode: string;
      facilityName: string;
      facilityLogoUrl: string;
    }>
  > =>
  (referralParams$) =>
    referralParams$.pipe(
      filter((referralParams) => Boolean(referralParams)),
      map((referralParams) => {
        const rawParams = referralParams.toLowerCase();
        const urlParams = new URLSearchParams(rawParams);
        return urlParams.get(FACILITY_CODE_PARAM);
      }),
      filter((facilityCode) => {
        return facilityCode && /^([a-z0-9]){3}$/i.test(facilityCode);
      }),
      mergeMap((facilityCode) =>
        from(
          facilityService.getFacilityInfoFromFacilityCode(facilityCode)
        ).pipe(
          map(({ data }: { data: FacilityInfoResponse }) => ({
            facilityCode: data.code,
            facilityName: data.name,
            facilityLogoUrl: data.logoUrl,
          })),
          catchError(() => {
            onFacilityReferralError && onFacilityReferralError();

            return EMPTY;
          })
        )
      ),
      map(({ facilityName, facilityCode, facilityLogoUrl }) => ({
        type: "facilityReferralSuccess",
        payload: {
          facilityCode,
          facilityName,
          facilityLogoUrl,
        },
      }))
    );
