import { Action, ActionMap } from "@reactables/core";
import { ControlRef } from "@reactables/forms";
import { HostedFieldsEvent } from "braintree-web";
import { CustomReducers } from "@reactables/forms";

export interface CCFieldActions extends ActionMap {
  validateCCFields: (payload: HostedFieldsEvent) => void;
  markAsCCFieldTouched: (payload: HostedFieldsEvent) => void;
  resetCCFields: () => void;
}

export const ccFields = (
  groupRef: ControlRef = []
): CustomReducers<CCFieldActions> => ({
  validateCCFields: (
    { updateValues },
    state,
    {
      payload: {
        fields: { number, cardholderName, cvv, expirationDate },
      },
    }: Action<HostedFieldsEvent>
  ) =>
    updateValues(state, {
      controlRef: groupRef,
      value: {
        number: number.isValid,
        cardholderName: cardholderName.isValid,
        cvv: cvv.isValid,
        expirationDate: expirationDate.isValid,
      },
    }),
  markAsCCFieldTouched: (
    { markControlAsTouched },
    state,
    { payload }: Action<HostedFieldsEvent>
  ) =>
    markControlAsTouched(state, {
      controlRef: [...groupRef, payload.emittedBy],
    }),
  resetCCFields: ({ resetControl }, state) => {
    if (state.form["payment"]) {
      state = resetControl(state, ["payment", "billing", "ccFieldsValidity"]);
    }

    return state;
  },
});
