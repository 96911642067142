import { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { RxMembershipSignUpProp } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/RxMembershipSignUp";

const GuardRedirect = ({
  rxMembershipSignUp: [, actions],
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
}) => {
  useEffect(() => {
    actions.applicationForm.resetControl([]);
  }, [actions]);
  return <Redirect to="/" />;
};

export default GuardRedirect;
