import { StoreProvider } from "@reactables/react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import rxApp from "./Features/Shared/Rx/RxApp";

const root = createRoot(document.getElementById("root"));
root.render(
  <StoreProvider rxStore={rxApp}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreProvider>
);
