export enum PromoCodeTypes {
  Discount = "discount",
  Trial = "trial",
}

export const promoCodeTypeOptions = [
  { label: "Discount", value: PromoCodeTypes.Discount },
  {
    label: "Trial",
    value: PromoCodeTypes.Trial,
  },
];
