import { of } from "rxjs";
import { Reducers, RxBuilder } from "@jauntin/reactables";
import { usStatesReducers } from "./Reducers/usStates.reducer";
import StatesService from "Services/StatesService";
import API from "../../../Helpers/API";
import { UsState } from "../Models/usState.model";

export interface AppState {
  usStates: Reducers.LoadableState<UsState[]>;
  maintenanceMode: boolean;
  googleMapsLoaded: boolean;
}

const initialState: AppState = {
  usStates: Reducers.loadableInitialState,
  maintenanceMode: false,
  googleMapsLoaded: false,
};

const RxApp = ({ statesService }: { statesService: StatesService }) =>
  RxBuilder({
    initialState,
    name: "rxApp",
    storeValue: true,
    sources: {
      fetchStates: of({}),
    },
    reducers: {
      ...usStatesReducers(statesService),
      maintenanceModeOn: (state) => ({ ...state, maintenanceMode: true }),
      googleMapsLoaded: (state) => ({ ...state, googleMapsLoaded: true }),
    },
  });

export default RxApp({
  statesService: new StatesService(new API()),
});
