import Logo from "@basicare/common/src/Assets/Images/basicare.white.svg";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";
import TwitterIcon from "@basicare/common/src/Assets/Images/twitter.png";
import { ReactComponent as InstagramIcon } from "@basicare/common/src/Assets/Images/instagram.svg";
import { ReactComponent as HipaaIcon } from "@basicare/common/src/Assets/Images/hipaa.svg";

const Footer = ({ referralParam = "" }: { referralParam?: string }) => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-xl-6">
          <div className="footer__basics">
            <div className="footer__basics-title">
              <img
                src={Logo}
                alt="BasiCare Plus"
                className="footer__basics-logo"
              />
            </div>
            <div className="footer__basics-text mb-3 mb-md-4">
              <p className="mb-3">
                Established in 2015, BasiCare Plus was designed to serve a
                growing number of under-insured and uninsured. Today we provide
                individual and bundled benefits all over the country serving
                small, medium and large businesses.
              </p>
              <p>
                BasiCare Plus products are offered in multi-employer groups,
                associations and staffing organizations, and now, individual
                families. Our products provide a very high value for a very low
                cost.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div>
            <div className="mt-3 mt-xl-0">
              <p className="mb-4">
                Our bundled services are made up of six unique products
                including three virtual professional services and three custom
                Rx programs. Custom bundling and standalone purchases are
                available.
              </p>
              <h6>CONTACT US</h6>
              <p className="mb-2">
                BasiCare Plus, 2148 Eagle Pass, Wooster, OH 44691
              </p>
              <p className="mb-4">
                <a className="mr-3 footer__link" href={`tel:${BCX_PHONE}`}>
                  {BCX_PHONE}
                </a>{" "}
                <a className="footer__link" href={`mailto:${BCX_EMAIL}`}>
                  {BCX_EMAIL}
                </a>
              </p>
              <div className="row">
                <div className="col-12 col-xl-auto mb-4">
                  <a
                    href="https://www.instagram.com/basicareplus/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                  <a
                    href="https://twitter.com/i/flow/login?redirect_after_login=%2Fbasicareplus"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={TwitterIcon}
                      alt="Basicare Plus Twitter"
                      className="ml-4"
                    />
                  </a>
                </div>
                <div className="col-12 col-xl-auto footer__hipaa d-flex align-items-center flex-grow-1 mb-4">
                  <a href="https://www.hhs.gov/hipaa/index.html">
                    <HipaaIcon />
                  </a>
                  <a
                    href={`https://basicareplus.com/privacy-policy/${referralParam}`}
                    className="ml-5 footer__link"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        NOTE: BasiCare Plus® and its Rx benefit are NOT INSURANCE. Use of
        telemedicine is strictly for non-emergency general illness. BasiCare
        Plus® provides direct access to prescriptions at a negotiated PBM price.
        Pricing is subject to change without notice. Over 65,000 pharmacies will
        accept your Rx Card, but it is not guaranteed. The final price is
        determined by your local pharmacy. Formulary and pricing are subject to
        change.
      </div>
    </div>
  </footer>
);

export default Footer;
