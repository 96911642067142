import { useEffect } from "react";
import { RxMembershipSignUpProp } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/RxMembershipSignUp";
import AsyncButton from "@basicare/common/src/Components/AsyncButton";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";
import { useReactable } from "@reactables/react";
import { RxToggle } from "@jauntin/reactables";
import Button from "@basicare/common/src/Components/Themed/Button";
import OopsMessage from "Features/Shared/Components/OopsMessage";

const ThankYou = ({
  rxMembershipSignUp: [state, actions],
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
}) => {
  useEffect(
    () => () => {
      actions.resetPurchase();
      actions.resetDownload();
    },
    [actions]
  );

  const { documents, purchase } = state;
  const purchaseData = purchase.data;
  const fileName = `${purchaseData?.subscriberNumber}-Membership-Documents.zip`;
  const [hasRepeatedDownloadError, repeatedDownloadError] = useReactable(
    RxToggle,
    false
  );

  return (
    <div className="thank-you container container--fullwidth-mobile">
      <div className="page-wrapper">
        <div className="page-content mt-lg-4">
          <div className="thank-you d-flex text-center flex-column align-items-center justify-content-center align-itemscenter">
            {purchaseData ? (
              <>
                <h1 className="text-uppercase mb-4">Thank you!</h1>
                <h5 className="mb-4">
                  Your membership subscription was successfully created.
                  <br />
                  Your membership number is:{" "}
                  <span className="c-secondary">
                    {purchaseData.subscriberNumber}
                  </span>
                </h5>
                <p className="mb-4">
                  You will receive an email with your membership card and
                  activation instructions.
                  <br />
                  You can also download your membership document by clicking the
                  button below.
                </p>

                {!documents.success ? (
                  <AsyncButton
                    spinningText="Downloading..."
                    spinning={documents.loading}
                    onClick={() =>
                      actions.downloadDocuments({
                        subscriberNumber: purchaseData.subscriberNumber,
                        pdfLink: purchaseData.pdfLink,
                        fileName,
                      })
                    }
                  >
                    <i className="fa fa-download mr-3"></i>Download Membership
                    Document
                  </AsyncButton>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        repeatedDownloadError.toggleOn();
                      }}
                    >
                      Download Successful!
                    </Button>
                    <br />
                    {hasRepeatedDownloadError && (
                      <div className="col-12 form-error pl-0 mb-3">
                        You have already downloaded your membership document.
                      </div>
                    )}
                    <div className="col-12 pl-0">
                      Can't find your membership document download on your
                      computer? Check your downloads folder.
                    </div>
                  </>
                )}

                {!documents.success && documents.error && (
                  <div className="col-12 form-error mt-3 pl-0 mt-4">
                    There was an error processing your request.
                    <br />
                    Please try again or contact us at{" "}
                    <a className="text-underline" href={`mailto: ${BCX_EMAIL}`}>
                      {BCX_EMAIL}
                    </a>{" "}
                    or{" "}
                    <a className="text-underline" href={`tel:${BCX_PHONE}`}>
                      {BCX_PHONE}
                    </a>
                  </div>
                )}

                <p className="mt-4">
                  If you have any questions, please contact us
                  <br />
                  at <a href={`mailto:${BCX_EMAIL}`}>{BCX_EMAIL}</a> or{" "}
                  <a href={`tel:${BCX_PHONE}`}>{BCX_PHONE}</a>
                </p>
              </>
            ) : (
              <OopsMessage />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
