import { from, of } from "rxjs";
import { map } from "rxjs/operators";
import { RxBuilder, Reducers, pollWhile } from "@jauntin/reactables";
import { mergeMap, catchError, tap } from "rxjs/operators";
import PaymentService from "Services/PaymentService";
import { Action } from "@reactables/core";

export interface RxDownloadDocumentsActions {
  downloadDocuments: (payload: {
    subscriberNumber: string;
    pdfLink: string;
    fileName: string;
  }) => void;
  resetDownload: () => void;
}

export const RxDownloadDocuments = ({
  initialState,
  paymentService,
  onDownloadDocumentsSuccess,
}: {
  initialState: Reducers.LoadableState<void>;
  paymentService: PaymentService;
  onDownloadDocumentsSuccess?: (
    data: string | File | Blob | Uint8Array,
    fileName: string
  ) => void;
}) =>
  RxBuilder({
    name: "rxDownloadDocuments",
    initialState: initialState || Reducers.loadableInitialState,
    reducers: {
      downloadDocuments: {
        reducer: Reducers.load,
        effects: [
          (download$) =>
            download$.pipe(
              mergeMap(
                ({
                  payload: { subscriberNumber, pdfLink, fileName },
                }: Action<{
                  subscriberNumber: string;
                  pdfLink: string;
                  fileName: string;
                }>) =>
                  of({}).pipe(
                    pollWhile({
                      source: () =>
                        from(
                          paymentService.getDocumentsStatus(subscriberNumber)
                        ),
                      isPollingActive: ({
                        data: { status },
                      }: {
                        data: { status: boolean };
                      }) => {
                        return !status;
                      },
                      emitOnlyLast: true,
                    }),
                    mergeMap(() =>
                      from(paymentService.getMembershipDocs(pdfLink))
                    ),
                    tap(
                      ({
                        data,
                      }: {
                        data: string | File | Blob | Uint8Array;
                      }) => {
                        onDownloadDocumentsSuccess &&
                          onDownloadDocumentsSuccess(data, fileName);
                      }
                    ),
                    map(() => ({ type: "downloadSuccess" })),
                    catchError((e) =>
                      of({ type: "downloadFailure", payload: e })
                    )
                  )
              )
            ),
        ],
      },
      resetDownload: () => Reducers.loadableInitialState,
      downloadSuccess: Reducers.loadSuccess,
      downloadFailure: Reducers.loadError,
    },
  });
