import Header from "../Components/Header";
import Footer from "../Components/Footer";
import React from "react";

const MainLayout = ({
  referralParam = "",
  facilityLogoUrl,
  facilityName,
  children,
}: {
  referralParam?: string;
  facilityLogoUrl?: string;
  facilityName?: string;
  children: React.ReactNode;
}) => (
  <div className="main-layout">
    <Header
      referralParam={referralParam}
      facilityLogoUrl={facilityLogoUrl}
      facilityName={facilityName}
    />
    {children}
    <Footer referralParam={referralParam} />
  </div>
);

export default MainLayout;
