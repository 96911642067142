import { FacilityInfoResponse } from "Features/MembershipSignUp/Models/facility.model";
import API from "Helpers/API";
import { AxiosResponse } from "axios";

class FacilityService {
  private api: API;

  constructor(api: API) {
    this.api = api;
  }

  public getFacilityInfoFromFacilityCode(
    facilityCode: string
  ): Promise<AxiosResponse<FacilityInfoResponse>> {
    return this.api.get({
      location: `/facility/referral/${facilityCode}`,
    });
  }
}

export default FacilityService;
