import { group, array, control } from "@reactables/forms";
import { Dependent } from "@basicare/common/src/Models/dependent.model";
import { dependent as dependentConfig } from "@basicare/common/src/Rx/Configs/dependent.config";

export const dependents = group({
  validators: ["addDependentFormClosed"],
  controls: {
    dependentsList: array({
      validators: ["oneSpouseMax"],
      controls: [],
    }),
    // addDependentForm: dependentForm, added here when adding a dependent
  },
});

export const dependentsArrayControl = (dependent: Dependent) =>
  group({
    controls: {
      dependent: dependentConfig(dependent),
      confirmed: control([true, "required"]),
    },
  });
