import { DiscountTypes } from "@basicare/common/src/Constants/discountTypes";
import { PlanTypes } from "@basicare/common/src/Constants/planTypes";
import { PromoCodeTypes } from "@basicare/common/src/Constants/promoCodeTypes";

export interface ApplyPromoCodePayload {
  code: string;
  subscriptionInterval?: PlanTypes; // If provided, will validate on frontend if selected subscriptionInterval matches the promoCode subscriptionInterval
}

export interface ApplyPromoCodeResponse {
  name: string;
  code: string;
  type: PromoCodeTypes;
  subscriptionInterval: PlanTypes;
  discountType: DiscountTypes;
  discountAmount: number;
  billingCycles: number;
  oldCost: number;
  newCost: number;
}

export interface ApplyPromoCodeError {
  message: string;
  promoCode?: ApplyPromoCodeResponse;
}

export const APPLY_PROMO_CODE_SUBSCRIPTION_INTERVAL_ERROR =
  "The Promo Code cannot be applied to the selected subscription interval.";
