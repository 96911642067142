import { Modal } from "react-bootstrap";
import { RxMembershipSignUpProp } from "Features/MembershipSignUp/Rx/RxMembershipSignUp/RxMembershipSignUp";
import Summary from "./Summary";

const SummaryDrawer = ({
  rxMembershipSignUp,
  show,
  close,
}: {
  rxMembershipSignUp: RxMembershipSignUpProp;
  show: boolean;
  close: () => void;
}) => (
  <>
    <Modal
      show={show}
      className="summary-drawer"
      contentClassName="summary-drawer__content"
      dialogClassName="summary-drawer__dialog"
      onHide={close}
    >
      <div className="h-100 overflow-auto bg-white">
        <Summary rxMembershipSignUp={rxMembershipSignUp} close={close} />
      </div>
    </Modal>
  </>
);

export default SummaryDrawer;
