import { Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { scrollToError } from "@jauntin/utilities";
import { useReactable } from "@reactables/react";
import { RxPaymentMethodUpdate } from "../Rx/RxPaymentMethodUpdate";
import PaymentService from "Services/PaymentService";
import API from "Helpers/API";
import OopsMessage from "Features/Shared/Components/OopsMessage";
import BrainTreeCCFields, {
  FieldValidStates,
} from "Features/Shared/Components/BrainTreeCCFields";
import { useHostedFields } from "Features/Shared/Hooks/useHostedFields";
import { BCX_EMAIL, BCX_PHONE } from "Constants/bcxContact";
import AsyncButton from "@basicare/common/src/Components/AsyncButton";
import Button from "@basicare/common/src/Components/Themed/Button";
import { hasControlTouchError } from "@basicare/common/src/Rx/Selectors/hasControlTouchError.selector";
import MainLayout from "Features/Shared/Layouts/MainLayout";

const PaymentMethodUpdate = () => {
  const { id } = useParams() as { id: string };
  const [state, actions] = useReactable(RxPaymentMethodUpdate, {
    paymentService: new PaymentService(new API()),
    link: id,
  });

  const { bindHostedFields, tokenizer } = useHostedFields();
  if (!state) return;

  const {
    verifyLinkRequest,
    ccFieldsValidityForm: { root, ...fieldValidStates },
    submission,
  } = state;

  if (verifyLinkRequest.loading)
    return (
      <>
        <div className="d-flex justify-content-center align-items-center h-100vh w-100">
          <Spinner animation="border" role="status" />
          <span className="ml-3">Loading...</span>
        </div>
      </>
    );
  return (
    <MainLayout>
      <div className="container container--fullwidth-mobile">
        <div className="page-wrapper">
          <div className="page-content mt-lg-4">
            <div className="d-flex flex-column align-items-center justify-content-center align-items-center">
              {(() => {
                if (verifyLinkRequest.error) return <OopsMessage />;

                if (submission.success)
                  return (
                    <div className="confirmation-message mh--300 d-flex flex-column justify-content-center align-items-center">
                      <h1 className="confirmation-message__heading font-weight-bold">
                        Thank you for updating your payment information.
                      </h1>
                      <p className="mt-4 confirmation-message__copy text-center">
                        Questions? Contact us at <br />
                        <a href={`mailto:${BCX_EMAIL}`}>{BCX_EMAIL}</a> or{" "}
                        <a href={`tel:${BCX_PHONE}`}>{BCX_PHONE}</a>
                      </p>
                    </div>
                  );

                const showActionBtn =
                  root.valid ||
                  state.submitTouched ||
                  hasControlTouchError(state.ccFieldsValidityForm);

                return (
                  <div className="payment-method-update">
                    <h1 className="text-center mb-4">
                      Please update your payment information below.
                    </h1>
                    <BrainTreeCCFields
                      onHostedFieldsLoaded={bindHostedFields}
                      onValidityChange={actions.fieldValidity.validateCCFields}
                      onBlur={actions.fieldValidity.markAsCCFieldTouched}
                      fieldValidStates={fieldValidStates as FieldValidStates}
                      enableShowErrors={state.submitTouched}
                    />

                    <div className="mt-4 d-flex justify-content-center">
                      {showActionBtn ? (
                        <AsyncButton
                          onClick={() => actions.submit(tokenizer)}
                          spinning={submission.loading}
                          disabled={!root.valid}
                        >
                          Submit <i className="ml-2 far fa-arrow-right" />
                        </AsyncButton>
                      ) : (
                        <Button
                          onClick={() => {
                            actions.touchSubmit();
                            scrollToError();
                          }}
                        >
                          Submit <i className="ml-2 far fa-arrow-right" />
                        </Button>
                      )}
                    </div>
                    {state.submission.error && (
                      <div className="form-error d-flex w-100 justify-content-center mt-3">
                        There was an error, please try again
                      </div>
                    )}
                  </div>
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default PaymentMethodUpdate;
