import { Action } from "@reactables/core";
import ProducerService from "Services/ProducerService";
import { OperatorFunction, from, EMPTY } from "rxjs";
import { mergeMap, map, catchError, filter } from "rxjs/operators";
import { ProducerInfoResponse } from "../../../Models/producer.model";
import { PRODUCER_CODE_REGEX } from "@basicare/common/src/Constants/codes";

const PRODUCER_CODE_PARAM = "pro";

/**
 * @description Provided a query string:
 * Operator looks for a producer code param and looks up Producer info
 */
export const handleProducerReferral =
  (
    producerService: ProducerService
  ): OperatorFunction<
    string,
    Action<{
      producerCode: string;
      producerLogoUrl: string;
    }>
  > =>
  (referralParams$) =>
    referralParams$.pipe(
      filter((referralParams) => Boolean(referralParams)),
      map((referralParams) => {
        const rawParams = referralParams.toLowerCase();
        const urlParams = new URLSearchParams(rawParams);

        return urlParams.get(PRODUCER_CODE_PARAM);
      }),
      filter((producerCode) => {
        return producerCode && PRODUCER_CODE_REGEX.test(producerCode);
      }),
      mergeMap((producerCode) =>
        from(producerService.getProducerInfoByProducerCode(producerCode)).pipe(
          map(({ data }: { data: ProducerInfoResponse }) => ({
            producerCode: data.commissionId,
            producerLogoUrl: data.logoUrl,
          })),
          catchError(() => EMPTY)
        )
      ),
      map(({ producerCode, producerLogoUrl }) => ({
        type: "producerReferralSuccess",
        payload: {
          producerCode,
          producerLogoUrl,
        },
      }))
    );
