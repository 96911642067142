import { ActionMap } from "@reactables/core";
import { CustomReducers } from "@reactables/forms";
import { placesAutocompleteReducers } from "@basicare/common/src/Rx/Reducers/placesAutocomplete.reducer";

export interface GeneralInformationActions extends ActionMap {
  selectPrimaryMemberPlace: (payload: google.maps.places.PlaceResult) => void;
  clearPrimaryMemberAddress: () => void;
}

const { selectPlace, clearAddress } = placesAutocompleteReducers([
  "generalInformation",
]);

export const generalInformationReducers: CustomReducers<GeneralInformationActions> =
  {
    selectPrimaryMemberPlace: selectPlace,
    clearPrimaryMemberAddress: clearAddress,
  };
