import { getHasVisibleDependentDobError } from "@basicare/common/src/Rx/Selectors/member.selector";
import { AddDependentsState } from "../RxAddDependents";
import { hasControlTouchError } from "@basicare/common/src/Rx/Selectors/hasControlTouchError.selector";

export const getShowActionBtn = (state: AddDependentsState) => {
  return (
    state.form.root.valid ||
    hasControlTouchError(state.form) ||
    getHasVisibleDependentDobError(state) ||
    state.submitTouched
  );
};
